import * as React from 'react';
import { IKapRegistration } from './KAPRegistrationListPage';
import { match } from 'react-router';
import { getRegistrations } from '../../KAPServer';
import { KAPBaseDataShow, KAPRequirementsShow } from './KAPComponents';

type KAPPrintRegistrationPageProps = {
    match: match<{id: string}>;
}

type KAPPrintRegistrationPageState = {
    registration?: IKapRegistration;
}

declare var $:any;

export class KAPPrintRegistrationPage extends React.Component<KAPPrintRegistrationPageProps, KAPPrintRegistrationPageState> {

    constructor(props: any) {
        super(props);

        this.state = {

        }
    }

    private onPrint() {
        window.print();
    }

    componentDidMount() {
        this.reloadAsync();
    }

    componentDidUpdate(prevProps: KAPPrintRegistrationPageProps) {
        if (this.props.match.params.id != prevProps.match.params.id) {
            this.reloadAsync();
        }
        
    }
    
    private onShowDialog(dialogRef: HTMLDivElement) {
        if (location.search.includes("dialog") && dialogRef) {
            $(dialogRef).foundation();
            $(dialogRef).foundation("open");
        }
    }

    async reloadAsync() {
        const registrations = await getRegistrations();
        if (registrations) {
            const registration = registrations.find(r => "" + r.id == this.props.match.params.id);
            this.setState({
                registration
            });
        }
    }

    render() {
        const kapRegistration = this.state.registration;

        if (!kapRegistration) return null;

        const registration = JSON.parse(kapRegistration.registration);
        
        return <div className="row" style={{marginTop: "1em"}}>
            <div className="column small-12">
                <img src="/img/kap/eke_logo.png"/>
                <img src="/img/kap/kap_logo.png" style={{marginLeft: "1em"}}/>
                
                <h3 style={{margin: "1em 0"}}>KAPOCS-iskolahálózat regisztráció</h3>
            </div>

            <div className="column small-12">
                <KAPBaseDataShow registration={registration} />

                <div style={{pageBreakAfter: "always"}} />
                
                <KAPRequirementsShow registration={registration} />
                
                <p>
                    Kijelentem, hogy az általam közölt adatok a valóságnak megfelelnek.
                </p>

            </div>

            <div className="column small-offset-8 small-4 text-center show-for-print">
                <br/>
                <br/>
                <hr/>
                Aláírás
            </div>

            <div className="column small-12">
                <button className="button primary hide-for-print" onClick={this.onPrint.bind(this)}>
                    Nyomtatás
                </button>
            </div>

            
            <div ref={this.onShowDialog.bind(this)} className="large reveal hide-for-print" data-reveal="">
                <h4>Köszönjük regisztrációját, rendszerünkben sikeresen rögzítettük!</h4>
                
                <p>
                    A regisztráció érvényesítése céljából kérjük, a NYOMTATÁS gombra kattintva nyomtassa ki a kitöltött jelentkezési lapot és postai úton küldje el számunkra az alábbi címre:
                </p>
                
                <p>
                    <strong>
                        Eszterházy Károly Katolikus Egyetem <br/>
                        Komplex Alapprogram <br/>
                        Eger <br/>
                        Eszterházy tér 1. 2.em.332/a <br/>
                        „KAPOCS-iskolahálózat regisztráció”
                    </strong>
                </p>

                <button className="close-button" data-close="" aria-label="Close modal" type="button">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>


        </div>;
    }
    
}

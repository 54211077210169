import * as React from 'react';
import { login, register } from '../../KAPServer';
import { Link } from 'react-router-dom';

type RegisterPageState = {
    email: string;
    password: string;
}

export class RegisterPage extends React.Component<{}, RegisterPageState> {

    constructor(props: any) {
        super(props);

        this.state = {
            email: "",
            password: ""
        }
    }

    private async onRegister() {
        const response = await register(this.state.email, this.state.password);
        if (response && response.success) {
            location.replace("/");
        } else {
            alert(response.error || "Szerveroldali hiba történt!");
        }
    }

    render() {
        return <div className="row">
            <div className="column small-12 medium-8 medium-offset-2" style={{paddingTop: "3em"}}>
                {
                    <>
                        <h4>Új felhasználó bejelentkezése</h4>
                        <p>
                            Kérjük, adja meg bejelentkezési adatait!
                        </p>
                    </>
                }

                <label>
                    Email cím:
                    <input 
                        type="text" value={this.state.email} 
                        onChange={e => this.setState({email: e.target.value})} 
                        onKeyDown={e => {if (e.key === "Enter") this.onRegister()}}
                        />
                </label>

                <label>
                    Jelszó:
                    <input 
                        type="password" value={this.state.password} 
                        onChange={e => this.setState({password: e.target.value})} 
                        onKeyDown={e => {if (e.key === "Enter") this.onRegister()}}
                        />
                </label>

                <button className="button primary" onClick={this.onRegister.bind(this)}>
                    Bejelentkezés
                </button>

            </div>
        </div>;
    }

}

import * as React from 'react';
import { registerKAPOCS } from '../../KAPServer';
import { History } from 'history';
import { app } from '../../App';
import { loadMe } from '../../framework/server/Auth';
import { KAPBaseDataEdit, KAPRequirementsEdit } from './KAPComponents';

export type KapRegistration = {
    institute_site_name: string;
    institute_site_county: string;
    institute_site_zip: string;
    institute_site_city: string;
    institute_site_address: string;
    institute_om_identifier: string;
    institute_site_identifier: string;
    institute_site_phone: string;
    institute_site_maintainer: string;
    institute_site_manager_name: string;
    institute_site_manager_phone: string;
    institute_site_manager_email: string;
    institute_site_contact_name: string;
    institute_site_contact_phone: string;
    institute_site_contact_email: string;
    kap_introduction_year: string;
    teaching_staff_count: string;

    check_1: boolean;
    check_2: boolean;
    check_2_2: boolean;
    check_3: boolean;
    check_4: boolean;
    check_5: boolean;
    check_6: boolean;
    check_7: boolean;
    
    check_6_v2: boolean;
    check_7_v2: boolean;

    check_8: boolean;
    check_9: boolean;
}

export const KAP_REGISTRATION_FIELDS: {key: keyof(KapRegistration), name: string, required: boolean, type?: "password"}[] = [
    /*
    {key: "institute_site_email", name: "Feladatellátási hely e-mail címe, bejelentkezési email cím", required: true},
    {key: "institute_site_password", name: "Bejelentkezési jelszó", required: true, type: "password"},
    */

    {key: "institute_site_name", name: "Feladatellátási hely megnevezése", required: true},
    {key: "institute_site_county", name: "A feladatellátási hely megyéje", required: true},
    {key: "institute_site_zip", name: "A feladatellátási hely irányítószáma", required: true},
    {key: "institute_site_city", name: "A feladatellátási hely települése", required: true},
    {key: "institute_site_address", name: "A feladatellátási hely pontos címe (utca, házszám)", required: true},
    {key: "institute_om_identifier", name: "OM azonosító", required: true},
    {key: "institute_site_identifier", name: "Feladatellátási hely azonosító", required: true},
    {key: "institute_site_phone", name: "Feladatellátási hely telefonszáma", required: true},
    {key: "institute_site_maintainer", name: "Fenntartó megnevezése", required: true},

    {key: "institute_site_manager_name", name: "Feladatellátási hely vezető neve", required: true},
    {key: "institute_site_manager_phone", name: "Feladatellátási hely vezető telefonszáma", required: true},
    {key: "institute_site_manager_email", name: "Feladatellátási hely vezető e-mail címe", required: true},
    {key: "institute_site_contact_name", name: "Kapcsolattartó neve (ha nem azonos az intézményvezetővel)", required: false},
    {key: "institute_site_contact_phone", name: "Kapcsolattartó telefonszáma", required: false},
    {key: "institute_site_contact_email", name: "Kapcsolattartó e-mail címe", required: false},

    {key: "kap_introduction_year", name: "A Komplex Alapprogram bevezetésének éve", required: true},
    {key: "teaching_staff_count", name: "Nevelőtestület létszáma", required: true},

]

export const KAP_REGISTRATION_CHECKBOXES: {key: keyof(KapRegistration), name: string }[] = [
    {key: "check_1", name: "Az iskola nevelőtestületének legalább 50%-a elvégezte a KAP-hoz kapcsolódó pedagógus-továbbképzések közül a két kötelező képzést (KAK, DFHT) és a két szabadon választott alprogrami képzést."},
    {key: "check_2", name: "Az intézmény vezetősége a pedagógiai programot a Komplex Alapprogramhoz igazítva kiegészítette vagy átdolgozta, amelyet a Komplex Alapprogram Országos Központ (komplexalapprogram@uni-eszterhazy.hu) számára elektronikus úton megküldött. A segédanyagot jelen tájékoztatóval együtt csatolva küldjük."},

    {key: "check_2_2", name: "Nyilatkozik arról, hogy 1 éven keresztül, két évfolyamon bevezette a Komplex Alapprogramot és két alprogrami foglalkozás megjelent az intézményben."},
    
    {key: "check_3", name: "A KAP bevezetésével érintett évfolyamokon az intézmény biztosítja a lehetőséget (akár évfolyamok összevonásával) minden tanuló számára, hogy legalább heti egy alkalommal minden alprogrami foglalkozáson (Digitális alapú, Életgyakorlat-alapú, Logikaalapú, Művészetalapú, Testmozgásalapú) részt tudjon venni."},
    {key: "check_4", name: "A KAP bevezetésével érintett évfolyamokon a pedagógusok a kötelező közismereti tanórák 10%-át a DFHT tanulási-tanítási stratégia (melynek 50%-a DFHT-KIP) alkalmazásával szervezik meg. A fennmaradó órák során a tananyagot, a tanulócsoportot – a tanulók egyedi tudásszintjét figyelembe véve – a pedagógus saját belátása szerint szabadon tervezi meg."},
    {key: "check_5", name: "A KAP bevezetésével érintett évfolyamokon a pedagógusok a tanmeneteket a KAP céljaihoz igazítva készítik el, megjelölve a DFHT tanítási-tanulási stratégiát alkalmazó tanórákat"},

    // CSAK A RÉGIEKHEZ 2020.09 előtt!!! AZ ÚJAKNÁL _v2!!!
    {key: "check_6", name: "Az intézményvezető a KAP bevezetésének tanévében két alkalommal lehetővé teszi, hogy alkalmanként négy DFHT tanítási-tanulási stratégiát alkalmazó, státuszkezelő óra (DFHT-KIP) és egy komplex óra hospitálása megvalósuljon."},
    // CSAK A RÉGIEKHEZ 2020.09 előtt!!! AZ ÚJAKNÁL _v2!!!
    {key: "check_7", name: "KAP-hoz kapcsolódó DFHT képzést elvégzett pedagógusok létszámának több mint a fele 4 saját, DFHT tanítási-tanulási stratégiára épülő, státuszkezelő módszert (DFHT-KIP) alkalmazó óratervezetet állít össze, amelyet feltölt a JTR (Jelenléti Támogatórendszer) felületére áttekintésre a szakmai támogatónak az előre meghatározott ütemterv szerint."},

    //2022-től nincs
    {key: "check_6_v2", name: "Az intézményvezető a KAP bevezetésének tanévében két alkalommal lehetővé teszi, hogy a DFHT támogató 3-5 pedagógus tanítási óráját megtekintse online formában vagy személyesen.  A megtekintett órák DFHT módszerrel megvalósult órák és komplex órák lehetnek."},

    //2022-től nincs
    {key: "check_7_v2", name: "A KAP bevezetésének tanévében a KAP-hoz kapcsolódó DFHT-képzést elvégzett pedagógusok létszámának több mint 50%-a 3 darab saját DFHT-KIP státuszkezelő módszert alkalmazó óratervezetet készít el a Komplex Alapprogram KAPU felületén a JTR (Jelenléti Támogatórendszer) csempén belül a DFHT-KIP óravázlatok menüpontban."},

    //2022-től nincs
    {key: "check_9", name: "Az intézményvezető (vagy a nevében eljáró kapcsolattartó) az EKE által biztosított elektronikus felületen reflektál az eltelt időszak folyamataira, megjelölve a nehézségeket, az előforduló problémákat és eredményeket egyaránt a tanév végén június 30-ig."},
]

export const KAP_REGISTRATION_GROUPS_SHOW: {[key: string]: (keyof(KapRegistration))[]} = {
    "<u>A regisztrációt megelőzően:</u>": ["check_1", "check_2", "check_2_2"],
    "<strong>Kérem, hogy jelölje (pipálja be) be azon feltételeket, amelyek igazak vagy azok megvalósítását vállalja a fent megjelölt feladatellátási hely esetében:<strong>": [],
    "<u>A regisztrációt követően a tagsági időszak alatt:</u>": ["check_3", "check_4", "check_5"],

    // 2022-től nincs
    //"<u>A KAP bevezetésének tanévében az intézmény együttműködik a Szakmai Támogatás Munkacsoporttal,  adott  esetben  a  Regionális  Képzési  Központokkal  az alábbiak  szerint:</u>": ["check_6", "check_7", "check_6_v2", "check_7_v2", "check_9"],
    //"": []
}

export const KAP_REGISTRATION_GROUPS_EDIT: {[key: string]: (keyof(KapRegistration))[]} = {
    "<u>A regisztrációt megelőzően:</u>": ["check_1", "check_2", "check_2_2"],
    "<strong>Kérem, hogy jelölje (pipálja be) be azon feltételeket, amelyek igazak vagy azok megvalósítását vállalja a fent megjelölt feladatellátási hely esetében:<strong>": [],
    "<u>A regisztrációt követően a tagsági időszak alatt:</u>": ["check_3", "check_4", "check_5"],
    
    // 2022-től nincs
    //"<u>A KAP bevezetésének tanévében az intézmény együttműködik a Szakmai Támogatás Munkacsoporttal,  adott  esetben  a  Regionális  Képzési  Központokkal  az alábbiak  szerint:</u>": ["check_7_v2", "check_9"], 
    //"<span style=\"color: red;\">A 2021-es Élménysuli pályázat elbírálásánál a járványügyi helyzetre való tekintettel az alábbi feltétel teljesülését nem vizsgáljuk:</span>": ["check_6_v2"]
    //"": []
}

export class KAPRegisterPage extends React.Component<{history: History}, {registration: KapRegistration, errors: string[], check1: boolean, check2: boolean}> {

    constructor(props: any) {
        super(props);

        this.state = {
            registration: {
                institute_site_name: "",
                institute_site_county: "",
                institute_site_zip: "",
                institute_site_city: "",
                institute_site_address: "",
                institute_om_identifier: "",
                institute_site_identifier: "",
                institute_site_phone: "",
                institute_site_maintainer: "",
                institute_site_manager_name: "",
                institute_site_manager_phone: "",
                institute_site_manager_email: "",
                institute_site_contact_name: "",
                institute_site_contact_phone: "",
                institute_site_contact_email: "",
                kap_introduction_year: "",
                teaching_staff_count: "",

                check_1: false,
                check_2: false,
                check_2_2: false,
                check_3: false,
                check_4: false,
                check_5: false,
                check_6: false,
                check_7: false,

                check_6_v2: true,
                check_7_v2: false,

                check_8: false,
                check_9: false,
            },
            errors: [],
            check1: false,
            check2: false,
        }
    }

    private onChange(key: keyof(KapRegistration), e: React.ChangeEvent<HTMLInputElement>) {
        this.state.registration[key] = e.target.value;

        this.setState({
            registration: this.state.registration
        });
    }

    private onCheck(key: keyof(KapRegistration), e: React.ChangeEvent<HTMLInputElement>) {
        this.state.registration[key] = e.target.checked;

        this.setState({
            registration: this.state.registration
        });
    }

    private async onRegister() {
        const errors: string[] = [];

        for(const field of KAP_REGISTRATION_FIELDS) {
            if (field.required && !this.state.registration[field.key]) {
                errors.push("A mező kitöltése kötelező: " + field.name);
            }
        }

        if (!this.state.check1) {
            errors.push("A regisztrációhoz el kell fogadnia az ÁFSZ-t");
        }
        if (!this.state.check2) {
            errors.push("A regisztrációhoz el kell fogadnia a hozzájárulást");
        }

        if (errors.length > 0) {
            this.setState({errors});
            return;
        }

        try {
            const response = await registerKAPOCS(this.state.registration);

            if (!response.success) {
                alert(response.error || "Szerver oldali hiba történt");
                return;
            }
            
            this.props.history.push("/kap/print/" + response.id + "?dialog");
            await loadMe();
            app.forceUpdate();

        } catch(e) {
            alert(e);
        }

    }

    render() {
        return <div className="row kap-registration-form" style={{marginTop: "1em"}}>
            <div className="column small-12">
                <img src="/img/kap/eke_logo.png"/>
                <img src="/img/kap/kap_logo.png" style={{marginLeft: "1em"}}/>
                
                <h3 style={{margin: "1em 0"}}>
                    Köszöntjük önt a KAPOCS-iskolahálózat regisztrációs felületén!
                </h3>

                <p>
                    A regisztrációs felületet az intézmény törvényes képviselője tölti ki és nyilatkozik a feltételek megvalósulásáról. 
                    Ebből a <i>Beküld</i> gomb lenyomásával a felület generál egy nyomtatható dokumentumot, amelyben az intézmény törvényes képviselője kijelenti és aláírásával igazolja, hogy az abban szereplő adatok és állítások a valóságnak megfelelnek, hitelesek és pontosak.
                </p>

                <KAPBaseDataEdit showPassword={true} registration={this.state.registration} onChange={this.onChange.bind(this)} />
            
                <p>
                    <strong>A regisztrációjukat abban az esetben tudjuk elfogadni, amennyiben az összes feltétel megjelölésre kerül.</strong>
                </p>

                <p>
                    <strong>Kérem, hogy jelölje (pipálja be) be azon feltételeket, amelyek igazak a fent megjelölt feladatellátási hely esetében:</strong>
                </p>
 
                <KAPRequirementsEdit registration={this.state.registration} onChange={this.onCheck.bind(this)} />

                <p>
                A kért adatokhoz csak az Eszterházy Károly Egyetem – Komplex Alapprogram projekt- és szakmai megvalósítói férnek hozzá; a kapott adatokat bizalmasan kezeljük, titkosítva tároljuk, harmadik félnek át nem adjuk, valamint csak a projekt- és szakmai dokumentáció elkészítéséhez használjuk fel.
                </p>

                <hr/>
                <label>
                    <input type="checkbox" checked={this.state.check1} onChange={e => this.setState({check1: e.target.checked})} />
                    Elfogadom az <a target="_blank" href="/doc/adatkezeles_kapocs.docx">adatkezelési tájékoztatót</a>.
                </label>
                <hr/>
                <label>
                    <input type="checkbox" checked={this.state.check2} onChange={e => this.setState({check2: e.target.checked})} />
                    Hozzájárulok, hogy a jelentkezési lapon megadott adataimat az EFOP-3.1.2-16-2016-00001 számú projekt keretében az Eszterházy Károly Katolikus Egyetem kezelje.
                </label>

                {
                    this.state.errors.length > 0 &&
                    <div className="callout alert">
                        <h5></h5>
                        <ul>
                            {this.state.errors.map(e => <li>{e}</li>)}
                        </ul>
                    </div>
                }
                <br/>
                <button className="button primary" onClick={this.onRegister.bind(this)}>
                    Beküld
                </button>
            </div>
        </div>;
    }
    
}

import * as React from 'react';

export class KAPFooter extends React.Component {
    
    render() {
        return <div className="hide-for-print" style={{backgroundColor: "#005083"}}>
        <div className="row">
          <div className="column small-12">
            <img src="/img/kap/kap_footer.jpg"/>
          </div>
        </div>
      </div>;
    }

}

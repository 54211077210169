import * as React from 'react';
import { login } from '../../KAPServer';
import { Link } from 'react-router-dom';

type KAPLoginPageState = {
    email: string;
    password: string;
}

export class KAPLoginPage extends React.Component<{}, KAPLoginPageState> {

    constructor(props: any) {
        super(props);

        this.state = {
            email: "",
            password: ""
        }
    }

    private async onLogin() {
        const response = await login(this.state.email, this.state.password);
        if (response && response.success) {
            location.replace("/");
        } else {
            alert("Hibás felhasználónév vagy jelszó!");
        }
    }

    render() {
        return <div className="row">
            <div className="column small-12 medium-8 medium-offset-2" style={{paddingTop: "3em"}}>
                {
                    <>
                        <h4>Köszöntjük a KAPOCS-iskolahálózat partnerintézménye oldalán!</h4>
                        <p>
                            A KAPOCS-iskolahálózat felépítése egy olyan kezdeményezés, amelynek célja, hogy összefogja a Komplex Alapprogramot megvalósító intézményeket, és szakmai fórumot biztosítson a program sikeres megvalósítása és fejlődése érdekében. A KAPOCS-iskolahálózathoz minden, a Komplex Alapprogramot 2018/2019-es és 2019/2020-as tanévben bevezető intézménynek folyamatosan van lehetősége önkéntesen csatlakozni.
                        </p>
                        <p>
                            A felületre történő belépést követően van lehetősége regisztrálni a KAPOCS-iskolahálózatba. A regisztráció elfogadása után ezen a felületen lehetőség nyílik benyújtani az Élménysuli cím pályázatot.
                        </p>
                        <p>
                            Ha már korábban bejelentkezett, kérjük, adja meg a bejelentkezési adatait. Ha még nem jelentkezett be, akkor az Új felhasználó vagyok gombra kattintva jelentkezzen be a felületre.
                        </p>
                    </>
                }

                <label>
                    Email cím:
                    <input 
                        type="text" value={this.state.email} 
                        onChange={e => this.setState({email: e.target.value})} 
                        onKeyDown={e => {if (e.key === "Enter") this.onLogin()}}
                        />
                </label>

                <label>
                    Jelszó:
                    <input 
                        type="password" value={this.state.password} 
                        onChange={e => this.setState({password: e.target.value})} 
                        onKeyDown={e => {if (e.key === "Enter") this.onLogin()}}
                        />
                </label>

                <button className="button primary" onClick={this.onLogin.bind(this)}>
                    Bejelentkezés
                </button>

                <hr/>

                <Link to="/register" className="button primary">
                    Új felhasználó vagyok
                </Link>

            </div>
        </div>;
    }

}

import * as React from 'react';
import { KapRegistration } from './KAPRegisterPage';
import { getRegistrations, updateRegistration } from '../../KAPServer';
import { match } from 'react-router';
import { History } from 'history';
import { KAPElmenysuliRequirementsEdit } from './KAPComponents';

export type KapElmenysuliRegistration = {
    check_e_1: boolean;
    check_e_2: boolean;
    check_e_3: boolean;
    check_e_4: boolean;
    check_e_5: boolean;
    check_e_6: boolean;
    check_e_7: boolean;
}

export const KAP_ELMENYSULI_REGISTRATION_CHECKBOXES: {key: keyof(KapElmenysuliRegistration), name: string }[] = [
    {key: "check_e_1", name: "az intézmény legalább egy éve a Komplex Alapprogramot megvalósítja a regisztrációkor adott válaszok alapján."},
    {key: "check_e_2", name: "az  intézményvezető  nyilatkozik  arról,  hogy  a  következő  évi  munkatervében megjelöli a DFHT tanulási-tanítási stratégia, a Ráhangolódást biztosító foglalkozások   megvalósításainak   lehetőségeit   és az   alprogramok   alkalmazási   lehetőségeit;"},
    {key: "check_e_3", name: "a KAP bevezetésével érintett évfolyamokon a pedagógusok a kötelező közismereti tanórák éves összóraszámának legalább 10%-ában Komplex órákat valósítanak meg;"},
    {key: "check_e_4", name: "a KAP bevezetésével  érintett  évfolyamokon  a tanmeneteket  a  KAP  céljaihoz  igazítva készítik el, megjelölve bennük az alprogrami kapcsolódási pontokat is (Komplex órák), a DFHT tanítási-tanulási stratégiát alkalmazó tanórák megjelölése mellett."},
    {key: "check_e_5", name: "KAP-hoz kapcsolódó DFHT képzést elvégzett pedagógusok létszámának több mint 70%-a 4 saját DFHT tanítási-tanulási stratégiára épülő, státuszkezelő módszert alkalmazó óratervezetet állított össze, amelyet feltöltött az SZTR online felületére (https://sztr.jelenleti.komplexalapprogram.hu) áttekintésre a szakmai támogatónak az előre meghatározott ütemterv szerint."},
    {key: "check_e_6", name: "az alprogrami foglalkozásokat tartó pedagógusok résztvevőként vagy foglalkozást tartó pedagógusként részt vettek egy-egy csoportszintű hospitáláson saját vagy más intézményben;"},
    {key: "check_e_7", name: "az intézményvezető legalább három alkalommal biztosított helyszínt alprogrami csoportszintű hospitálásoknak."},
]

export const KAP_ELMENYSULI_REGISTRATION_GROUPS: {[key: string]: (keyof(KapElmenysuliRegistration))[]} = {
    "A pályázat benyújtásának tanévében::": ["check_e_1", "check_e_2", "check_e_3", "check_e_4"],
    "A KAP bevezetésének tanévében:": ["check_e_5"],
    "A KAP bevezetésének tanévében és/vagy az azt követő egy tanév során:": ["check_e_6", "check_e_7"]
}

type KAPElmenysuliRegisterPageProps = {
    match: match<{id: string}>,
    history: History
}

type KAPElmenysuliRegisterPageState = {
    registration?: KapRegistration,
    elmenysuliRegistration: KapElmenysuliRegistration,
    errors: string[]
}

export class KAPElmenysuliRegisterPage extends React.Component<KAPElmenysuliRegisterPageProps, KAPElmenysuliRegisterPageState> {

    constructor(props: KAPElmenysuliRegisterPageProps) {
        super(props);

        this.state = {
            elmenysuliRegistration: {
                check_e_1: false,
                check_e_2: false,
                check_e_3: false,
                check_e_4: false,
                check_e_5: false,
                check_e_6: false,
                check_e_7: false,
            },
            errors: []
        }
    }

    async componentDidMount() {
        const registrations = await getRegistrations();
        if (registrations) {
            const registration = registrations.find(r => "" + r.id == this.props.match.params.id);
            if (registration) {
                this.setState({
                    registration: JSON.parse(registration.registration)
                });
            }
        }
    }

    private onCheckElmenysuli(key: keyof(KapElmenysuliRegistration), e: React.ChangeEvent<HTMLInputElement>) {
        this.state.elmenysuliRegistration[key] = e.target.checked;

        this.setState({
            elmenysuliRegistration: this.state.elmenysuliRegistration
        });
    }
    
    private async onRegister() {
        if (!this.state.registration) return;

        const response = await updateRegistration(this.props.match.params.id, this.state.registration, this.state.elmenysuliRegistration);

        if (!response.success) {
            alert(response.error || "Szerver oldali hiba történt");
            return;
        }

        this.props.history.push("/kap/elmenysuli_print/" + this.props.match.params.id + "?dialog");
    }
    
    render() {
        if (!this.state.registration) {
            return null;
        }
        
        return <div className="row kap-registration-form" style={{marginTop: "1em"}}>
            <div className="column small-12">
                <img src="/img/kap/eke_logo.png"/>
                <img src="/img/kap/kap_logo.png" style={{marginLeft: "1em"}}/>
                
                <h3 style={{margin: "1em 0"}}>
                    Köszöntjük az Élménysuli 2019 cím pályázat oldalán!
                </h3>

                <p>
                    Az Élménysuli olyan pályázat által elnyerhető cím, amelyet azon KAPOCS-iskolahálózat partnerintézményei nyernek el egy tanévre, akik a program elemeit mintaértékűen valósítják meg, és az újszerű módszerek elsajátítása érdekében igénybe veszik az SZTR által biztosított szakmai támogatást. A nyertes pályázók jogosultak lesznek az Élménysuli logójának és az Élménysuli megnevezés használatára.
                </p>

                <p>
                    Kérjük, jelölje be azokat az állításokat, amelyek igazak az ön intézményére! A pályázatot az intézmény törvényes képviselője tölti ki és nyilatkozik a kritériumok megvalósulásáról. Ebből a Beküld gomb lenyomásával a felület generál egy nyomtatható dokumentumot, amelyben az intézmény törvényes képviselője kijelenti és aláírásával igazolja, hogy az abban szereplő adatok és állítások a valóságnak megfelelnek, hitelesek és pontosak.
                </p>
                <p>
                    Mivel ön már bejelentkezett a saját profiljába ezért az intézmény adatait és a KAPOCS-iskolahálózat regisztrációjánál bejelölt kritériumokat a rendszer hozzágenerálja az elkészített dokumentumhoz.
                </p>

                <h4>A regisztrációs feltételeken túl az Élménysuli kiválósági követelményei a következők:</h4>
                <div style={{marginLeft: "3em"}}>

                <KAPElmenysuliRequirementsEdit elmenysuliRegistration={this.state.elmenysuliRegistration} onChange={this.onCheckElmenysuli.bind(this)} />

                </div>

                {
                    this.state.errors.length > 0 &&
                    <div className="callout alert">
                        <h5></h5>
                        <ul>
                            {this.state.errors.map(e => <li>{e}</li>)}
                        </ul>
                    </div>
                }
                <br/>
                <button className="button primary" onClick={this.onRegister.bind(this)}>
                    Beküld  
                </button>

            </div>
        </div>;
    }

}

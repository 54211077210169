import * as React from 'react';
import { KAP_REGISTRATION_FIELDS, KapRegistration, KAP_REGISTRATION_CHECKBOXES, KAP_REGISTRATION_GROUPS_SHOW, KAP_REGISTRATION_GROUPS_EDIT } from './KAPRegisterPage';
import { KAP_ELMENYSULI_REGISTRATION_GROUPS, KapElmenysuliRegistration, KAP_ELMENYSULI_REGISTRATION_CHECKBOXES } from './KAPElmenysuliRegisterPage';
import { KapElmenysuli2020NewRegistration, KAP_ELMENYSULI_2020_NEW_REGISTRATION_GROUPS, KAP_ELMENYSULI_2020_NEW_REGISTRATION_CHECKBOXES, KAP_ELMENYSULI_2020_RENEW_REGISTRATION_GROUPS, KAP_ELMENYSULI_2020_RENEW_REGISTRATION_CHECKBOXES, KapElmenysuli2020RenewRegistration } from './KAPElmenysuli2020RegisterPage';
import { KapElmenysuli2021NewRegistration, KAP_ELMENYSULI_2021_NEW_REGISTRATION_GROUPS, KAP_ELMENYSULI_2021_NEW_REGISTRATION_CHECKBOXES, KapElmenysuli2021OnceRenewRegistration, KAP_ELMENYSULI_2021_ONCE_RENEW_REGISTRATION_GROUPS, KAP_ELMENYSULI_2021_ONCE_RENEW_REGISTRATION_CHECKBOXES, KapElmenysuli2021TwiceRenewRegistration, KAP_ELMENYSULI_2021_TWICE_RENEW_REGISTRATION_GROUPS, KAP_ELMENYSULI_2021_TWICE_RENEW_REGISTRATION_CHECKBOXES } from './KAPElmenysuli2021RegisterPage';
import { KapElmenysuli2022NewRegistration, KAP_ELMENYSULI_2022_NEW_REGISTRATION_GROUPS, KAP_ELMENYSULI_2022_NEW_REGISTRATION_CHECKBOXES, KapElmenysuli2022OnceRenewRegistration, KAP_ELMENYSULI_2022_ONCE_RENEW_REGISTRATION_GROUPS, KAP_ELMENYSULI_2022_ONCE_RENEW_REGISTRATION_CHECKBOXES, KapElmenysuli2022TwiceRenewRegistration, KAP_ELMENYSULI_2022_TWICE_RENEW_REGISTRATION_GROUPS, KAP_ELMENYSULI_2022_TWICE_RENEW_REGISTRATION_CHECKBOXES } from './KAPElmenysuli2022RegisterPage';
import { KapElmenysuli2023NewRegistration, KAP_ELMENYSULI_2023_NEW_REGISTRATION_GROUPS, KAP_ELMENYSULI_2023_NEW_REGISTRATION_CHECKBOXES, KapElmenysuli2023OnceRenewRegistration, KAP_ELMENYSULI_2023_ONCE_RENEW_REGISTRATION_GROUPS, KAP_ELMENYSULI_2023_ONCE_RENEW_REGISTRATION_CHECKBOXES, KapElmenysuli2023TwiceRenewRegistration, KAP_ELMENYSULI_2023_TWICE_RENEW_REGISTRATION_GROUPS, KAP_ELMENYSULI_2023_TWICE_RENEW_REGISTRATION_CHECKBOXES } from './KAPElmenysuli2023RegisterPage';
import { KapElmenysuli2024NewRegistration, KAP_ELMENYSULI_2024_NEW_REGISTRATION_GROUPS, KAP_ELMENYSULI_2024_NEW_REGISTRATION_CHECKBOXES, KapElmenysuli2024OnceRenewRegistration, KAP_ELMENYSULI_2024_ONCE_RENEW_REGISTRATION_GROUPS, KAP_ELMENYSULI_2024_ONCE_RENEW_REGISTRATION_CHECKBOXES, KapElmenysuli2024TwiceRenewRegistration, KAP_ELMENYSULI_2024_TWICE_RENEW_REGISTRATION_GROUPS, KAP_ELMENYSULI_2024_TWICE_RENEW_REGISTRATION_CHECKBOXES } from './KAPElmenysuli2024RegisterPage';

export class KAPBaseDataShow extends React.Component<{registration: KapRegistration}> {

    render() {
        return <table className="unstriped">
            <tbody>
            {
                KAP_REGISTRATION_FIELDS.map(f => {
                    if (!this.props.registration[f.key]) return;

                    return <tr>
                            <td style={{width: "50%"}}>{f.name}</td>
                            <td style={{width: "50%"}}><strong>{this.props.registration[f.key]}</strong></td>
                        </tr>

                })
            }
            </tbody>
        </table>
    }

}

export class KAPRequirementsShow extends React.Component<{registration: KapRegistration}> {

    render() {
        return Object.entries(KAP_REGISTRATION_GROUPS_SHOW).map((entry, index) => {
            if (!entry[1].some(e => this.props.registration[e] as boolean)) return null;

            return <React.Fragment key={index}>
                    <p dangerouslySetInnerHTML={{__html: entry[0]}}></p>
                    
                    {entry[1].map((check, index2) => {
                        const checkbox = KAP_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                        if (!checkbox) return;

                        if (!this.props.registration[check]) return;

                        return <p style={{marginLeft: "2em"}} key={index2}>{checkbox.name}</p>
                    })}
                </React.Fragment>
        })
    }

}

export class KAPElmenysuliRequirementsShow extends React.Component<{elmenysuli: KapElmenysuliRegistration}> {
    render() {
        return Object.entries(KAP_ELMENYSULI_REGISTRATION_GROUPS).map((entry, index) => {
                if (!entry[1].some(e => this.props.elmenysuli[e] as boolean)) return null;

                return <React.Fragment key={index}>
                        <p>{entry[0]}</p>
                        
                        {entry[1].map((check, index2) => {
                            const checkbox = KAP_ELMENYSULI_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                            if (!checkbox) return;

                            if (!this.props.elmenysuli[check]) return;

                            return <p style={{marginLeft: "2em"}} key={index2}>{checkbox.name}</p>
                        })}
                    </React.Fragment>
            })
    }
}

export class KAPElmenysuli2020RequirementsShow extends React.Component<{elmenysuli: any}> {
    render() {
        const newRegistration = Object.entries(KAP_ELMENYSULI_2020_NEW_REGISTRATION_GROUPS).map((entry, index) => {
                if (!entry[1].some(e => this.props.elmenysuli[e] as boolean)) return null;

                return <React.Fragment key={index}>
                        <p><u>{entry[0]}</u></p>
                        
                        {entry[1].map((check, index2) => {
                            const checkbox = KAP_ELMENYSULI_2020_NEW_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                            if (!checkbox) return;

                            if (!this.props.elmenysuli[check]) return;

                            return <p style={{marginLeft: "2em"}} key={index2}>{checkbox.name}</p>
                        })}
                    </React.Fragment>
            });
        const renewRegistration = Object.entries(KAP_ELMENYSULI_2020_RENEW_REGISTRATION_GROUPS).map((entry, index) => {
                if (!entry[1].some(e => this.props.elmenysuli[e] as boolean)) return null;

                return <React.Fragment key={index}>
                        {/*<p><u>{entry[0]}</u></p>*/}
                        
                        {entry[1].map((check, index2) => {
                            const checkbox = KAP_ELMENYSULI_2020_RENEW_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                            if (!checkbox) return;

                            if (!this.props.elmenysuli[check]) return;

                            return <p style={{marginLeft: "2em"}} key={index2}>{checkbox.name}</p>
                        })}
                    </React.Fragment>
            });
        return newRegistration.concat(renewRegistration);
    }
}


export class KAPElmenysuli2021RequirementsShow extends React.Component<{elmenysuli: any}> {
    render() {
        const newRegistration = Object.entries(KAP_ELMENYSULI_2021_NEW_REGISTRATION_GROUPS).map((entry, index) => {
                if (!entry[1].some(e => this.props.elmenysuli[e] as boolean)) return null;

                return <React.Fragment key={index}>
                        <p dangerouslySetInnerHTML={{__html: entry[0]}}></p>
                        
                        {entry[1].map((check, index2) => {
                            const checkbox = KAP_ELMENYSULI_2021_NEW_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                            if (!checkbox) return;

                            if (!this.props.elmenysuli[check]) return;

                            return <p style={{marginLeft: "2em"}} key={index2}>{checkbox.name}</p>
                        })}
                    </React.Fragment>
            });
            const onceRenewRegistration = Object.entries(KAP_ELMENYSULI_2021_ONCE_RENEW_REGISTRATION_GROUPS).map((entry, index) => {
                if (!entry[1].some(e => this.props.elmenysuli[e] as boolean)) return null;

                return <React.Fragment key={index}>
                        <p dangerouslySetInnerHTML={{__html: entry[0]}}></p>
                        
                        {entry[1].map((check, index2) => {
                            const checkbox = KAP_ELMENYSULI_2021_ONCE_RENEW_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                            if (!checkbox) return;

                            if (!this.props.elmenysuli[check]) return;

                            return <p style={{marginLeft: "2em"}} key={index2}>{checkbox.name}</p>
                        })}
                    </React.Fragment>
            });
            const twiceRenewRegistration = Object.entries(KAP_ELMENYSULI_2021_TWICE_RENEW_REGISTRATION_GROUPS).map((entry, index) => {
                    if (!entry[1].some(e => this.props.elmenysuli[e] as boolean)) return null;
    
                    return <React.Fragment key={index}>
                            <p dangerouslySetInnerHTML={{__html: entry[0]}}></p>
                            
                            {entry[1].map((check, index2) => {
                                const checkbox = KAP_ELMENYSULI_2021_TWICE_RENEW_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                                if (!checkbox) return;
    
                                if (!this.props.elmenysuli[check]) return;
    
                                return <p style={{marginLeft: "2em"}} key={index2}>{checkbox.name}</p>
                            })}
                        </React.Fragment>
                });
        return newRegistration.concat(onceRenewRegistration).concat(twiceRenewRegistration);
    }
}


export class KAPElmenysuli2022RequirementsShow extends React.Component<{elmenysuli: any}> {
    render() {
        const newRegistration = Object.entries(KAP_ELMENYSULI_2022_NEW_REGISTRATION_GROUPS).map((entry, index) => {
                if (!entry[1].some(e => this.props.elmenysuli[e] as boolean)) return null;

                return <React.Fragment key={index}>
                        <p dangerouslySetInnerHTML={{__html: entry[0]}}></p>
                        
                        {entry[1].map((check, index2) => {
                            const checkbox = KAP_ELMENYSULI_2022_NEW_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                            if (!checkbox) return;

                            if (!this.props.elmenysuli[check]) return;

                            return <p style={{marginLeft: "2em"}} key={index2}>{checkbox.name}</p>
                        })}
                    </React.Fragment>
            });
            const onceRenewRegistration = Object.entries(KAP_ELMENYSULI_2022_ONCE_RENEW_REGISTRATION_GROUPS).map((entry, index) => {
                if (!entry[1].some(e => this.props.elmenysuli[e] as boolean)) return null;

                return <React.Fragment key={index}>
                        <p dangerouslySetInnerHTML={{__html: entry[0]}}></p>
                        
                        {entry[1].map((check, index2) => {
                            const checkbox = KAP_ELMENYSULI_2022_ONCE_RENEW_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                            if (!checkbox) return;

                            if (!this.props.elmenysuli[check]) return;

                            return <p style={{marginLeft: "2em"}} key={index2}>{checkbox.name}</p>
                        })}
                    </React.Fragment>
            });
            const twiceRenewRegistration = Object.entries(KAP_ELMENYSULI_2022_TWICE_RENEW_REGISTRATION_GROUPS).map((entry, index) => {
                    if (!entry[1].some(e => this.props.elmenysuli[e] as boolean)) return null;
    
                    return <React.Fragment key={index}>
                            <p dangerouslySetInnerHTML={{__html: entry[0]}}></p>
                            
                            {entry[1].map((check, index2) => {
                                const checkbox = KAP_ELMENYSULI_2022_TWICE_RENEW_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                                if (!checkbox) return;
    
                                if (!this.props.elmenysuli[check]) return;
    
                                return <p style={{marginLeft: "2em"}} key={index2}>{checkbox.name}</p>
                            })}
                        </React.Fragment>
                });
        return newRegistration.concat(onceRenewRegistration).concat(twiceRenewRegistration);
    }
}


export class KAPElmenysuli2023RequirementsShow extends React.Component<{elmenysuli: any}> {
    render() {
        const newRegistration = Object.entries(KAP_ELMENYSULI_2023_NEW_REGISTRATION_GROUPS).map((entry, index) => {
                if (!entry[1].some(e => this.props.elmenysuli[e] as boolean)) return null;

                return <React.Fragment key={index}>
                        <p dangerouslySetInnerHTML={{__html: entry[0]}}></p>
                        
                        {entry[1].map((check, index2) => {
                            const checkbox = KAP_ELMENYSULI_2023_NEW_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                            if (!checkbox) return;

                            if (!this.props.elmenysuli[check]) return;

                            return <p style={{marginLeft: "2em"}} key={index2}>{checkbox.name}</p>
                        })}
                    </React.Fragment>
            });
            const onceRenewRegistration = Object.entries(KAP_ELMENYSULI_2023_ONCE_RENEW_REGISTRATION_GROUPS).map((entry, index) => {
                if (!entry[1].some(e => this.props.elmenysuli[e] as boolean)) return null;

                return <React.Fragment key={index}>
                        <p dangerouslySetInnerHTML={{__html: entry[0]}}></p>
                        
                        {entry[1].map((check, index2) => {
                            const checkbox = KAP_ELMENYSULI_2023_ONCE_RENEW_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                            if (!checkbox) return;

                            if (!this.props.elmenysuli[check]) return;

                            return <p style={{marginLeft: "2em"}} key={index2}>{checkbox.name}</p>
                        })}
                    </React.Fragment>
            });
            const twiceRenewRegistration = Object.entries(KAP_ELMENYSULI_2023_TWICE_RENEW_REGISTRATION_GROUPS).map((entry, index) => {
                    if (!entry[1].some(e => this.props.elmenysuli[e] as boolean)) return null;
    
                    return <React.Fragment key={index}>
                            <p dangerouslySetInnerHTML={{__html: entry[0]}}></p>
                            
                            {entry[1].map((check, index2) => {
                                const checkbox = KAP_ELMENYSULI_2023_TWICE_RENEW_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                                if (!checkbox) return;
    
                                if (!this.props.elmenysuli[check]) return;
    
                                return <p style={{marginLeft: "2em"}} key={index2}>{checkbox.name}</p>
                            })}
                        </React.Fragment>
                });
        return newRegistration.concat(onceRenewRegistration).concat(twiceRenewRegistration);
    }
}

export class KAPElmenysuli2024RequirementsShow extends React.Component<{elmenysuli: any}> {
    render() {
        const newRegistration = Object.entries(KAP_ELMENYSULI_2024_NEW_REGISTRATION_GROUPS).map((entry, index) => {
                if (!entry[1].some(e => this.props.elmenysuli[e] as boolean)) return null;

                return <React.Fragment key={index}>
                        <p dangerouslySetInnerHTML={{__html: entry[0]}}></p>
                        
                        {entry[1].map((check, index2) => {
                            const checkbox = KAP_ELMENYSULI_2024_NEW_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                            if (!checkbox) return;

                            if (!this.props.elmenysuli[check]) return;

                            return <p style={{marginLeft: "2em"}} key={index2}>{checkbox.name}</p>
                        })}
                    </React.Fragment>
            });
            const onceRenewRegistration = Object.entries(KAP_ELMENYSULI_2024_ONCE_RENEW_REGISTRATION_GROUPS).map((entry, index) => {
                if (!entry[1].some(e => this.props.elmenysuli[e] as boolean)) return null;

                return <React.Fragment key={index}>
                        <p dangerouslySetInnerHTML={{__html: entry[0]}}></p>
                        
                        {entry[1].map((check, index2) => {
                            const checkbox = KAP_ELMENYSULI_2024_ONCE_RENEW_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                            if (!checkbox) return;

                            if (!this.props.elmenysuli[check]) return;

                            return <p style={{marginLeft: "2em"}} key={index2}>{checkbox.name}</p>
                        })}
                    </React.Fragment>
            });
            const twiceRenewRegistration = Object.entries(KAP_ELMENYSULI_2024_TWICE_RENEW_REGISTRATION_GROUPS).map((entry, index) => {
                    if (!entry[1].some(e => this.props.elmenysuli[e] as boolean)) return null;
    
                    return <React.Fragment key={index}>
                            <p dangerouslySetInnerHTML={{__html: entry[0]}}></p>
                            
                            {entry[1].map((check, index2) => {
                                const checkbox = KAP_ELMENYSULI_2024_TWICE_RENEW_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                                if (!checkbox) return;
    
                                if (!this.props.elmenysuli[check]) return;
    
                                return <p style={{marginLeft: "2em"}} key={index2}>{checkbox.name}</p>
                            })}
                        </React.Fragment>
                });
        return newRegistration.concat(onceRenewRegistration).concat(twiceRenewRegistration);
    }
}


export class KAPBaseDataEdit extends React.Component<{showPassword: boolean, registration: KapRegistration, onChange: (key: keyof(KapRegistration), e: React.ChangeEvent<HTMLInputElement>) => void}> {

    render() {
        return KAP_REGISTRATION_FIELDS.map(field => {
            return <label>
                {field.name}:
                {
                    field.required && <span style={{color: "red"}}> *</span>
                }
                <input type={field.type === "password" ? "password" : "text"} value={this.props.registration[field.key] as string} onChange={e => this.props.onChange(field.key, e)} />
            </label>
        })
    }

}

export class KAPRequirementsEdit extends React.Component<{registration: KapRegistration, onChange: (key: keyof(KapRegistration), e: React.ChangeEvent<HTMLInputElement>) => void}> {
    render() {
        return Object.entries(KAP_REGISTRATION_GROUPS_EDIT).map((entry, index) => {
            return <React.Fragment key={index}>
                    <p dangerouslySetInnerHTML={{__html: entry[0]}}></p>
                    
                    {entry[1].map((check, index2) => {
                        const checkbox = KAP_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                        if (!checkbox) return;

                        return <React.Fragment key={index2}>
                            <label style={{marginLeft: "3em"}}>
                                <input 
                                    type="checkbox" 
                                    disabled={checkbox.key === "check_6_v2"}
                                    checked={this.props.registration[check] as boolean} 
                                    onChange={e => this.props.onChange(check as any, e)} />
                                {checkbox.name}
                            </label>
                            <hr/>
                        </React.Fragment>
                    })}
                </React.Fragment>
        })
    }
}

export class KAPElmenysuliRequirementsEdit extends React.Component<{elmenysuliRegistration: KapElmenysuliRegistration, onChange: (key: keyof(KapElmenysuliRegistration), e: React.ChangeEvent<HTMLInputElement>) => void}> {
    render() {
        return Object.entries(KAP_ELMENYSULI_REGISTRATION_GROUPS).map((entry, index) => {
            return <React.Fragment key={index}>
                    <p>{entry[0]}</p>
                    
                    {entry[1].map((check, index2) => {
                        const checkbox = KAP_ELMENYSULI_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                        if (!checkbox) return;

                        return <React.Fragment key={index2}>
                            <label style={{marginLeft: "3em"}}>
                                <input type="checkbox" checked={this.props.elmenysuliRegistration[check] as boolean} onChange={e => this.props.onChange(check as any, e)} />
                                {checkbox.name}
                            </label>
                            <hr/>
                        </React.Fragment>
                    })}
                </React.Fragment>
        })
    }
}


export class KAPElmenysuli2020NewRequirementsEdit extends React.Component<{elmenysuliRegistration: KapElmenysuli2020NewRegistration, onChange: (key: keyof(KapElmenysuli2020NewRegistration), e: React.ChangeEvent<HTMLInputElement>) => void}> {
    render() {
        return Object.entries(KAP_ELMENYSULI_2020_NEW_REGISTRATION_GROUPS).map((entry, index) => {
            return <React.Fragment key={index}>
                    <p><u>{entry[0]}</u></p>
                    
                    {entry[1].map((check, index2) => {
                        const checkbox = KAP_ELMENYSULI_2020_NEW_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                        if (!checkbox) return;

                        return <React.Fragment key={index2}>
                            <label style={{marginLeft: "3em"}}>
                                <input type="checkbox" checked={this.props.elmenysuliRegistration[check] as boolean} onChange={e => this.props.onChange(check as any, e)} />
                                {checkbox.name}
                            </label>
                            <hr/>
                        </React.Fragment>
                    })}
                </React.Fragment>
        })
    }
}


export class KAPElmenysuli2020RenewRequirementsEdit extends React.Component<{elmenysuliRegistration: KapElmenysuli2020RenewRegistration, onChange: (key: keyof(KapElmenysuli2020RenewRegistration), e: React.ChangeEvent<HTMLInputElement>) => void}> {
    render() {
        return Object.entries(KAP_ELMENYSULI_2020_RENEW_REGISTRATION_GROUPS).map((entry, index) => {
            return <React.Fragment key={index}>
                    <p><u>{entry[0]}</u></p>
                    
                    {entry[1].map((check, index2) => {
                        const checkbox = KAP_ELMENYSULI_2020_RENEW_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                        if (!checkbox) return;

                        return <React.Fragment key={index2}>
                            <label style={{marginLeft: "3em"}}>
                                <input type="checkbox" checked={this.props.elmenysuliRegistration[check] as boolean} onChange={e => this.props.onChange(check as any, e)} />
                                {checkbox.name}
                            </label>
                            <hr/>
                        </React.Fragment>
                    })}
                </React.Fragment>
        })
    }
}





export class KAPElmenysuli2021NewRequirementsEdit extends React.Component<{elmenysuliRegistration: KapElmenysuli2021NewRegistration, onChange: (key: keyof(KapElmenysuli2021NewRegistration), e: React.ChangeEvent<HTMLInputElement>) => void}> {
    render() {
        return Object.entries(KAP_ELMENYSULI_2021_NEW_REGISTRATION_GROUPS).map((entry, index) => {
            return <React.Fragment key={index}>
                    <p dangerouslySetInnerHTML={{__html: entry[0]}}></p>
                    
                    {entry[1].map((check, index2) => {
                        const checkbox = KAP_ELMENYSULI_2021_NEW_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                        if (!checkbox) return;

                        return <React.Fragment key={index2}>
                            <label style={{marginLeft: "3em"}}>
                                <input type="checkbox" checked={this.props.elmenysuliRegistration[check] as boolean} onChange={e => this.props.onChange(check as any, e)} />
                                {checkbox.name}
                            </label>
                            <hr/>
                        </React.Fragment>
                    })}
                </React.Fragment>
        })
    }
}


export class KAPElmenysuli2021OnceRenewRequirementsEdit extends React.Component<{elmenysuliRegistration: KapElmenysuli2021OnceRenewRegistration, onChange: (key: keyof(KapElmenysuli2021OnceRenewRegistration), e: React.ChangeEvent<HTMLInputElement>) => void}> {
    render() {
        return Object.entries(KAP_ELMENYSULI_2021_ONCE_RENEW_REGISTRATION_GROUPS).map((entry, index) => {
            return <React.Fragment key={index}>
                    <p dangerouslySetInnerHTML={{__html: entry[0]}}></p>
                    
                    {entry[1].map((check, index2) => {
                        const checkbox = KAP_ELMENYSULI_2021_ONCE_RENEW_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                        if (!checkbox) return;

                        return <React.Fragment key={index2}>
                            <label style={{marginLeft: "3em"}}>
                                <input type="checkbox" checked={this.props.elmenysuliRegistration[check] as boolean} onChange={e => this.props.onChange(check as any, e)} />
                                {checkbox.name}
                            </label>
                            <hr/>
                        </React.Fragment>
                    })}
                </React.Fragment>
        })
    }
}


export class KAPElmenysuli2021TwiceRenewRequirementsEdit extends React.Component<{elmenysuliRegistration: KapElmenysuli2021TwiceRenewRegistration, onChange: (key: keyof(KapElmenysuli2021TwiceRenewRegistration), e: React.ChangeEvent<HTMLInputElement>) => void}> {
    render() {
        return Object.entries(KAP_ELMENYSULI_2021_TWICE_RENEW_REGISTRATION_GROUPS).map((entry, index) => {
            return <React.Fragment key={index}>
                    <p dangerouslySetInnerHTML={{__html: entry[0]}}></p>
                    
                    {entry[1].map((check, index2) => {
                        const checkbox = KAP_ELMENYSULI_2021_TWICE_RENEW_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                        if (!checkbox) return;

                        return <React.Fragment key={index2}>
                            <label style={{marginLeft: "3em"}}>
                                <input type="checkbox" checked={this.props.elmenysuliRegistration[check] as boolean} onChange={e => this.props.onChange(check as any, e)} />
                                {checkbox.name}
                            </label>
                            <hr/>
                        </React.Fragment>
                    })}
                </React.Fragment>
        })
    }
}


export class KAPElmenysuli2022NewRequirementsEdit extends React.Component<{elmenysuliRegistration: KapElmenysuli2022NewRegistration, onChange: (key: keyof(KapElmenysuli2022NewRegistration), e: React.ChangeEvent<HTMLInputElement>) => void}> {
    render() {
        return Object.entries(KAP_ELMENYSULI_2022_NEW_REGISTRATION_GROUPS).map((entry, index) => {
            return <React.Fragment key={index}>
                    <p dangerouslySetInnerHTML={{__html: entry[0]}}></p>
                    
                    {entry[1].map((check, index2) => {
                        const checkbox = KAP_ELMENYSULI_2022_NEW_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                        if (!checkbox) return;

                        return <React.Fragment key={index2}>
                            <label style={{marginLeft: "3em"}}>
                                <input type="checkbox" checked={this.props.elmenysuliRegistration[check] as boolean} onChange={e => this.props.onChange(check as any, e)} />
                                {checkbox.name}
                            </label>
                            <hr/>
                        </React.Fragment>
                    })}
                </React.Fragment>
        })
    }
}


export class KAPElmenysuli2022OnceRenewRequirementsEdit extends React.Component<{elmenysuliRegistration: KapElmenysuli2022OnceRenewRegistration, onChange: (key: keyof(KapElmenysuli2022OnceRenewRegistration), e: React.ChangeEvent<HTMLInputElement>) => void}> {
    render() {
        return Object.entries(KAP_ELMENYSULI_2022_ONCE_RENEW_REGISTRATION_GROUPS).map((entry, index) => {
            return <React.Fragment key={index}>
                    <p dangerouslySetInnerHTML={{__html: entry[0]}}></p>
                    
                    {entry[1].map((check, index2) => {
                        const checkbox = KAP_ELMENYSULI_2022_ONCE_RENEW_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                        if (!checkbox) return;

                        return <React.Fragment key={index2}>
                            <label style={{marginLeft: "3em"}}>
                                <input type="checkbox" checked={this.props.elmenysuliRegistration[check] as boolean} onChange={e => this.props.onChange(check as any, e)} />
                                {checkbox.name}
                            </label>
                            <hr/>
                        </React.Fragment>
                    })}
                </React.Fragment>
        })
    }
}


export class KAPElmenysuli2022TwiceRenewRequirementsEdit extends React.Component<{elmenysuliRegistration: KapElmenysuli2022TwiceRenewRegistration, onChange: (key: keyof(KapElmenysuli2022TwiceRenewRegistration), e: React.ChangeEvent<HTMLInputElement>) => void}> {
    render() {
        return Object.entries(KAP_ELMENYSULI_2022_TWICE_RENEW_REGISTRATION_GROUPS).map((entry, index) => {
            return <React.Fragment key={index}>
                    <p dangerouslySetInnerHTML={{__html: entry[0]}}></p>
                    
                    {entry[1].map((check, index2) => {
                        const checkbox = KAP_ELMENYSULI_2022_TWICE_RENEW_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                        if (!checkbox) return;

                        return <React.Fragment key={index2}>
                            <label style={{marginLeft: "3em"}}>
                                <input type="checkbox" checked={this.props.elmenysuliRegistration[check] as boolean} onChange={e => this.props.onChange(check as any, e)} />
                                {checkbox.name}
                            </label>
                            <hr/>
                        </React.Fragment>
                    })}
                </React.Fragment>
        })
    }
}


export class KAPElmenysuli2023NewRequirementsEdit extends React.Component<{elmenysuliRegistration: KapElmenysuli2023NewRegistration, onChange: (key: keyof(KapElmenysuli2023NewRegistration), e: React.ChangeEvent<HTMLInputElement>) => void}> {
    render() {
        return Object.entries(KAP_ELMENYSULI_2023_NEW_REGISTRATION_GROUPS).map((entry, index) => {
            return <React.Fragment key={index}>
                    <p dangerouslySetInnerHTML={{__html: entry[0]}}></p>
                    
                    {entry[1].map((check, index2) => {
                        const checkbox = KAP_ELMENYSULI_2023_NEW_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                        if (!checkbox) return;

                        return <React.Fragment key={index2}>
                            <label style={{marginLeft: "3em"}}>
                                <input type="checkbox" checked={this.props.elmenysuliRegistration[check] as boolean} onChange={e => this.props.onChange(check as any, e)} />
                                {checkbox.name}
                            </label>
                            <hr/>
                        </React.Fragment>
                    })}
                </React.Fragment>
        })
    }
}


export class KAPElmenysuli2023OnceRenewRequirementsEdit extends React.Component<{elmenysuliRegistration: KapElmenysuli2023OnceRenewRegistration, onChange: (key: keyof(KapElmenysuli2023OnceRenewRegistration), e: React.ChangeEvent<HTMLInputElement>) => void}> {
    render() {
        return Object.entries(KAP_ELMENYSULI_2023_ONCE_RENEW_REGISTRATION_GROUPS).map((entry, index) => {
            return <React.Fragment key={index}>
                    <p dangerouslySetInnerHTML={{__html: entry[0]}}></p>
                    
                    {entry[1].map((check, index2) => {
                        const checkbox = KAP_ELMENYSULI_2023_ONCE_RENEW_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                        if (!checkbox) return;

                        return <React.Fragment key={index2}>
                            <label style={{marginLeft: "3em"}}>
                                <input type="checkbox" checked={this.props.elmenysuliRegistration[check] as boolean} onChange={e => this.props.onChange(check as any, e)} />
                                {checkbox.name}
                            </label>
                            <hr/>
                        </React.Fragment>
                    })}
                </React.Fragment>
        })
    }
}


export class KAPElmenysuli2023TwiceRenewRequirementsEdit extends React.Component<{elmenysuliRegistration: KapElmenysuli2023TwiceRenewRegistration, onChange: (key: keyof(KapElmenysuli2023TwiceRenewRegistration), e: React.ChangeEvent<HTMLInputElement>) => void}> {
    render() {
        return Object.entries(KAP_ELMENYSULI_2023_TWICE_RENEW_REGISTRATION_GROUPS).map((entry, index) => {
            return <React.Fragment key={index}>
                    <p dangerouslySetInnerHTML={{__html: entry[0]}}></p>
                    
                    {entry[1].map((check, index2) => {
                        const checkbox = KAP_ELMENYSULI_2023_TWICE_RENEW_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                        if (!checkbox) return;

                        return <React.Fragment key={index2}>
                            <label style={{marginLeft: "3em"}}>
                                <input type="checkbox" checked={this.props.elmenysuliRegistration[check] as boolean} onChange={e => this.props.onChange(check as any, e)} />
                                {checkbox.name}
                            </label>
                            <hr/>
                        </React.Fragment>
                    })}
                </React.Fragment>
        })
    }
}

export class KAPElmenysuli2024NewRequirementsEdit extends React.Component<{elmenysuliRegistration: KapElmenysuli2024NewRegistration, onChange: (key: keyof(KapElmenysuli2024NewRegistration), e: React.ChangeEvent<HTMLInputElement>) => void}> {
    render() {
        return Object.entries(KAP_ELMENYSULI_2024_NEW_REGISTRATION_GROUPS).map((entry, index) => {
            return <React.Fragment key={index}>
                    <p dangerouslySetInnerHTML={{__html: entry[0]}}></p>
                    
                    {entry[1].map((check, index2) => {
                        const checkbox = KAP_ELMENYSULI_2024_NEW_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                        if (!checkbox) return;

                        return <React.Fragment key={index2}>
                            <label style={{marginLeft: "3em"}}>
                                <input type="checkbox" checked={this.props.elmenysuliRegistration[check] as boolean} onChange={e => this.props.onChange(check as any, e)} />
                                {checkbox.name}
                            </label>
                            <hr/>
                        </React.Fragment>
                    })}
                </React.Fragment>
        })
    }
}


export class KAPElmenysuli2024OnceRenewRequirementsEdit extends React.Component<{elmenysuliRegistration: KapElmenysuli2024OnceRenewRegistration, onChange: (key: keyof(KapElmenysuli2024OnceRenewRegistration), e: React.ChangeEvent<HTMLInputElement>) => void}> {
    render() {
        return Object.entries(KAP_ELMENYSULI_2024_ONCE_RENEW_REGISTRATION_GROUPS).map((entry, index) => {
            return <React.Fragment key={index}>
                    <p dangerouslySetInnerHTML={{__html: entry[0]}}></p>
                    
                    {entry[1].map((check, index2) => {
                        const checkbox = KAP_ELMENYSULI_2024_ONCE_RENEW_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                        if (!checkbox) return;

                        return <React.Fragment key={index2}>
                            <label style={{marginLeft: "3em"}}>
                                <input type="checkbox" checked={this.props.elmenysuliRegistration[check] as boolean} onChange={e => this.props.onChange(check as any, e)} />
                                {checkbox.name}
                            </label>
                            <hr/>
                        </React.Fragment>
                    })}
                </React.Fragment>
        })
    }
}

export class KAPElmenysuli2024TwiceRenewRequirementsEdit extends React.Component<{elmenysuliRegistration: KapElmenysuli2024TwiceRenewRegistration, onChange: (key: keyof(KapElmenysuli2024TwiceRenewRegistration), e: React.ChangeEvent<HTMLInputElement>) => void}> {
    render() {
        return Object.entries(KAP_ELMENYSULI_2024_TWICE_RENEW_REGISTRATION_GROUPS).map((entry, index) => {
            return <React.Fragment key={index}>
                    <p dangerouslySetInnerHTML={{__html: entry[0]}}></p>
                    
                    {entry[1].map((check, index2) => {
                        const checkbox = KAP_ELMENYSULI_2024_TWICE_RENEW_REGISTRATION_CHECKBOXES.find(c => c.key == check);
                        if (!checkbox) return;

                        return <React.Fragment key={index2}>
                            <label style={{marginLeft: "3em"}}>
                                <input type="checkbox" checked={this.props.elmenysuliRegistration[check] as boolean} onChange={e => this.props.onChange(check as any, e)} />
                                {checkbox.name}
                            </label>
                            <hr/>
                        </React.Fragment>
                    })}
                </React.Fragment>
        })
    }
}


import { getMe } from "../../KAPServer";

export interface IUserInfo {
    id: number;
    email?: string;
    is_admin: number;
}

export let me: IUserInfo;

export async function loadMe() {
    me = await getMe();
}

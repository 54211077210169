import * as React from 'react';
import { KapRegistration } from './KAPRegisterPage';
import { getRegistrations, updateRegistration } from '../../KAPServer';
import { match } from 'react-router';
import { History } from 'history';
import { KAPElmenysuliRequirementsEdit, KAPElmenysuli2020NewRequirementsEdit, KAPElmenysuli2020RenewRequirementsEdit } from './KAPComponents';
import { REGISTRATION_ACCEPTED_ID } from './KAPRegistrationListPage';

export type KapElmenysuli2020NewRegistration = {
    check_n_1: boolean;
    check_n_2: boolean;
    check_n_3: boolean;
    check_n_4: boolean;
    check_n_5: boolean;
}

export const KAP_ELMENYSULI_2020_NEW_REGISTRATION_CHECKBOXES: {key: keyof(KapElmenysuli2020NewRegistration), name: string }[] = [
    {key: "check_n_1", name: "Az intézmény legalább egy éve a Komplex Alapprogramot megvalósítja a regisztrációkor adott válaszok alapján."},
    {key: "check_n_2", name: "Az  intézményvezető  nyilatkozik  arról,  hogy  a  következő  évi  munkatervében megjelöli a DFHT tanulási-tanítási stratégia, a Ráhangolódást biztosító foglalkozások   megvalósításainak   lehetőségeit   és az   alprogramok   alkalmazási   lehetőségeit."},
    {key: "check_n_3", name: "A KAP bevezetésével érintett évfolyamokon a pedagógusok a kötelező közismereti tanórák éves összóraszámának legalább 10%-ában Komplex órákat valósítanak meg."},
    {key: "check_n_4", name: "A KAP bevezetésével  érintett  évfolyamokon  a tanmeneteket  a  KAP  céljaihoz  igazítva készítik el, megjelölve bennük az alprogrami kapcsolódási pontokat is (Komplex órák), a DFHT tanítási-tanulási stratégiát alkalmazó tanórák megjelölése mellett."},
    {key: "check_n_5", name: "KAP-hoz kapcsolódó DFHT-képzést elvégzett pedagógusok létszámának több mint 70%-a 4 saját DFHT-KIP státuszkezelő módszert alkalmazó óratervezetet készített el a Komplex Alapprogram KAPU felületén a JTR (Jelenléti Támogatórendszer) csempén belül a DFHT-KIP óravázlatok menüpontban."},
]

export const KAP_ELMENYSULI_2020_NEW_REGISTRATION_GROUPS: {[key: string]: (keyof(KapElmenysuli2020NewRegistration))[]} = {
    "A pályázat benyújtásának tanévében:": ["check_n_1", "check_n_2", "check_n_3", "check_n_4"],
    "A KAP bevezetésének tanévében:": ["check_n_5"],
}

export type KapElmenysuli2020RenewRegistration = {
    check_r_1: boolean;
    check_r_2: boolean;
    check_r_3: boolean;
    check_r_4: boolean;
    check_r_5: boolean;
}

export const KAP_ELMENYSULI_2020_RENEW_REGISTRATION_CHECKBOXES: {key: keyof(KapElmenysuli2020RenewRegistration), name: string }[] = [
    {key: "check_r_1", name: "Az intézmény egy tanévig az Élménysuli-címet elnyerte, és a Komplex Alapprogramot továbbra is megvalósítja."},
    {key: "check_r_2", name: "Vállalja, hogy a 2020/2021-es tanévben egy szakmai napot önállóan megszervez és megvalósít abban a tankerületben, ahová tartozik."},
    {key: "check_r_3", name: "Egy szakmai napot önállóan megszervezett és megvalósított abban a tankerületben, ahová tartozik. A pályázat mellékleteként postai úton elküldi a szakmai nap megvalósulását igazoló dokumentumo(ka)t."},
    {key: "check_r_4", name: "Vállalja, hogy a 2020/2021-es tanévben kapcsolatot tart legalább egy KAPOCS-iskolahálózati partnerintézménnyel és biztosítja számára a szakmai támogatást (hospitálás lehetőségének biztosítása a fogadó intézményben, tanácsadás). A pályázat mellékleteként postai úton elküldi azon KAPOCS-iskolahálózati partnerintézmény(ek) nevét, címét, elérhetőségét, akivel a 2020/2021-es tanévben kapcsolatot tart és biztosítja a szakmai támogatást."},
    {key: "check_r_5", name: "Kapcsolatot tartott legalább egy KAPOCS-iskolahálózati partnerintézménnyel és biztosította számára a szakmai támogatást (hospitálás lehetőségének biztosítása a fogadó intézményben, tanácsadás). A pályázat mellékleteként postai úton elküldi azon KAPOCS-iskolahálózati partnerintézmény(ek) nevét, címét, elérhetőségét, akivel a 2019/20-as tanévben kapcsolatot tartott, ami magában foglalja a kapcsolattartás formáját és azt az információt, hogy hány alkalommal történt szakmai támogatás."},
]

export const KAP_ELMENYSULI_2020_RENEW_REGISTRATION_GROUPS: {[key: string]: (keyof(KapElmenysuli2020RenewRegistration))[]} = {
    "Az Élménysuli-cím egy tanévig érvényes, amely egy tanév elteltével megújítható, illetve újra pályázható. Amennyiben az Élménysuli-címét szeretné megújítani, kérem, hogy jelölje (pipálja be) azon feltételeket, amelyek igazak a fent megjelölt feladatellátási hely esetében:": ["check_r_1"],
    "Kérem, hogy az alábbi két feltétel közül jelölje (pipálja be) azt az egy feltételt, amely igaz a fent megjelölt feladatellátási hely esetében:": ["check_r_2", "check_r_3"],
    "Kérem, hogy az alábbi két feltétel közül jelölje (pipálja be) azt a feltételt, amely igaz a fent megjelölt feladatellátási hely esetében:": ["check_r_4", "check_r_5"],
}

type KAPElmenysuli2020RegisterPageProps = {
    match: match<{id: string}>,
    history: History
}

type KAPElmenysuli2020RegisterPageState = {
    elmenysuliStatusId?: number,
    registration?: KapRegistration,
    elmenysuliNewRegistration: KapElmenysuli2020NewRegistration,
    elmenysuliRenewRegistration: KapElmenysuli2020RenewRegistration,
    errors: string[]
}

export class KAPElmenysuli2020RegisterPage extends React.Component<KAPElmenysuli2020RegisterPageProps, KAPElmenysuli2020RegisterPageState> {

    constructor(props: KAPElmenysuli2020RegisterPageProps) {
        super(props);

        this.state = {
            elmenysuliNewRegistration: {
                check_n_1: false,
                check_n_2: false,
                check_n_3: false,
                check_n_4: false,
                check_n_5: false,
            },
            elmenysuliRenewRegistration: {
                check_r_1: false,
                check_r_2: false,
                check_r_3: false,
                check_r_4: false,
                check_r_5: false,
            },
            errors: []
        }
    }

    async componentDidMount() {
        const registrations = await getRegistrations();
        if (registrations) {
            const registration = registrations.find(r => "" + r.id == this.props.match.params.id);
            if (registration) {
                this.setState({
                    elmenysuliStatusId: registration.elmenysuli_status_id,
                    registration: JSON.parse(registration.registration)
                });
            }
        }
    }

    private onCheckElmenysuli2020New(key: keyof(KapElmenysuli2020NewRegistration), e: React.ChangeEvent<HTMLInputElement>) {
        this.state.elmenysuliNewRegistration[key] = e.target.checked;

        this.setState({
            elmenysuliNewRegistration: this.state.elmenysuliNewRegistration
        });
    }

    private onCheckElmenysuli2020Renew(key: keyof(KapElmenysuli2020RenewRegistration), e: React.ChangeEvent<HTMLInputElement>) {
        this.state.elmenysuliRenewRegistration[key] = e.target.checked;

        if (key === "check_r_2" && this.state.elmenysuliRenewRegistration["check_r_3"]) {
            this.state.elmenysuliRenewRegistration["check_r_3"] = false;
        }
        if (key === "check_r_3" && this.state.elmenysuliRenewRegistration["check_r_2"]) {
            this.state.elmenysuliRenewRegistration["check_r_2"] = false;
        }
        if (key === "check_r_4" && this.state.elmenysuliRenewRegistration["check_r_5"]) {
            this.state.elmenysuliRenewRegistration["check_r_5"] = false;
        }
        if (key === "check_r_5" && this.state.elmenysuliRenewRegistration["check_r_4"]) {
            this.state.elmenysuliRenewRegistration["check_r_4"] = false;
        }

        this.setState({
            elmenysuliRenewRegistration: this.state.elmenysuliRenewRegistration
        });
    }
    
    private async onRegister() {
        if (!this.state.registration) return;

        const response = await updateRegistration(this.props.match.params.id, this.state.registration, null, Object.assign({}, this.state.elmenysuliNewRegistration, this.state.elmenysuliRenewRegistration));

        if (!response.success) {
            alert(response.error || "Szerver oldali hiba történt");
            return;
        }

        this.props.history.push("/kap/elmenysuli_2020_print/" + this.props.match.params.id + "?dialog");
    }
    
    render() {
        if (!this.state.registration) {
            return null;
        }
        
        return <div className="row kap-registration-form" style={{marginTop: "1em"}}>
            <div className="column small-12">
                <img src="/img/kap/eke_logo.png"/>
                <img src="/img/kap/kap_logo.png" style={{marginLeft: "1em"}}/>
                
                <h3 style={{margin: "1em 0"}}>
                    Köszöntjük az Élménysuli cím {this.state.elmenysuliStatusId == REGISTRATION_ACCEPTED_ID ? "megújítása" : ""} pályázat oldalán!
                </h3>

                <p>
                    Az Élménysuli olyan pályázat által elnyerhető cím, amelyet azon KAPOCS-iskolahálózat partnerintézményei nyernek el egy tanévre, akik a program elemeit mintaértékűen valósítják meg, és az újszerű módszerek elsajátítása érdekében igénybe veszik az SZTR által biztosított szakmai támogatást. A nyertes pályázók jogosultak lesznek az Élménysuli logójának és az Élménysuli megnevezés használatára.
                </p>

                <p>
                    Kérjük, jelölje be azokat az állításokat, amelyek igazak az ön intézményére! A pályázatot az intézmény törvényes képviselője tölti ki és nyilatkozik a kritériumok megvalósulásáról. Ebből a Beküld gomb lenyomásával a felület generál egy nyomtatható dokumentumot, amelyben az intézmény törvényes képviselője kijelenti és aláírásával igazolja, hogy az abban szereplő adatok és állítások a valóságnak megfelelnek, hitelesek és pontosak.
                </p>
                <p>
                    Mivel ön már bejelentkezett a saját profiljába ezért az intézmény adatait {this.state.elmenysuliStatusId != REGISTRATION_ACCEPTED_ID ? "és a KAPOCS-iskolahálózat regisztrációjánál bejelölt kritériumokat": ""} a rendszer hozzágenerálja az elkészített dokumentumhoz.
                </p>

                <h4>A regisztrációs feltételeken túl az Élménysuli {this.state.elmenysuliStatusId == REGISTRATION_ACCEPTED_ID ? "cím megújításának" : ""} kiválósági követelményei a következők:</h4>
                <div style={{marginLeft: "3em"}}>
                {
                    this.state.elmenysuliStatusId == REGISTRATION_ACCEPTED_ID
                    ?
                    <KAPElmenysuli2020RenewRequirementsEdit elmenysuliRegistration={this.state.elmenysuliRenewRegistration} onChange={this.onCheckElmenysuli2020Renew.bind(this)} />
                    :
                    <KAPElmenysuli2020NewRequirementsEdit elmenysuliRegistration={this.state.elmenysuliNewRegistration} onChange={this.onCheckElmenysuli2020New.bind(this)} />
                }

                </div>

                {
                    this.state.errors.length > 0 &&
                    <div className="callout alert">
                        <h5></h5>
                        <ul>
                            {this.state.errors.map(e => <li>{e}</li>)}
                        </ul>
                    </div>
                }
                <br/>
                <button className="button primary" onClick={this.onRegister.bind(this)}>
                    Beküld  
                </button>

            </div>
        </div>;
    }

}

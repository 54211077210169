import * as React from 'react';
import { KapRegistration } from './KAPRegisterPage';
import { getRegistrations, updateRegistration } from '../../KAPServer';
import { match } from 'react-router';
import { History } from 'history';
import { KAPElmenysuli2021NewRequirementsEdit, KAPElmenysuli2021OnceRenewRequirementsEdit, KAPElmenysuli2021TwiceRenewRequirementsEdit } from './KAPComponents';
import { REGISTRATION_ACCEPTED_ID, IKapRegistration } from './KAPRegistrationListPage';

export type KapElmenysuli2021NewRegistration = {
    check_n_1: boolean;
    check_n_2: boolean;
    check_n_3: boolean;
    check_n_4: boolean;
    check_n_5: boolean;
}

export const KAP_ELMENYSULI_2021_NEW_REGISTRATION_CHECKBOXES: {key: keyof(KapElmenysuli2021NewRegistration), name: string }[] = [
    {key: "check_n_1", name: "Az intézmény legalább egy éve a Komplex Alapprogramot megvalósítja a regisztrációkor adott válaszok alapján."},
    {key: "check_n_2", name: "Az intézményvezető nyilatkozik arról, hogy a következő évi munkatervében megjelöli a DFHT tanulási-tanítási stratégia, a Ráhangolódást biztosító foglalkozások megvalósításainak lehetőségeit és az alprogramok alkalmazási lehetőségeit."},
    {key: "check_n_3", name: "A KAP bevezetésével érintett évfolyamokon a pedagógusok a kötelező közismereti tanórák éves összóraszámának legalább 10%-ában Komplex órákat valósítanak meg."},
    {key: "check_n_4", name: "A KAP bevezetésével érintett évfolyamokon a tanmeneteket a KAP céljaihoz igazítva készítik el, megjelölve bennük az alprogrami kapcsolódási pontokat is (Komplex órák) a DFHT tanítási-tanulási stratégiát alkalmazó tanórák megjelölése mellett."},
    {key: "check_n_5", name: "KAP-hoz kapcsolódó DFHT-képzést elvégzett pedagógusok létszámának több mint 70%-a 3 darab saját DFHT-KIP státuszkezelő módszert alkalmazó óratervezetet készített el a Komplex Alapprogram KAPU felületén a JTR (Jelenléti Támogatórendszer) csempén belül a DFHT-KIP óravázlatok menüpontban."},
]

export const KAP_ELMENYSULI_2021_NEW_REGISTRATION_GROUPS: {[key: string]: (keyof(KapElmenysuli2021NewRegistration))[]} = {
    "<u>2021-es Élménysuli pályázat kritériumai a járványügyi helyzetre való tekintettel a következők <br/> A pályázat benyújtásának tanévében:</u>": ["check_n_1", "check_n_2", "check_n_3", "check_n_4"],
    "<u>A KAP bevezetését követően</u>:": ["check_n_5"],
}

export type KapElmenysuli2021OnceRenewRegistration = {
    check_o_1: boolean;
    check_o_2: boolean;
    check_o_3: boolean;
}

export const KAP_ELMENYSULI_2021_ONCE_RENEW_REGISTRATION_CHECKBOXES: {key: keyof(KapElmenysuli2021OnceRenewRegistration), name: string }[] = [
    {key: "check_o_1", name: "Az intézmény egy tanévig az Élménysuli címet elnyerte, és a Komplex Alapprogramot továbbra is megvalósítja."},
    {key: "check_o_2", name: "Vállalja, hogy a 2021/2022-es tanévben egy szakmai napot önállóan megszervez és megvalósít."},
    {key: "check_o_3", name: "Vállalja, hogy kapcsolatot tart legalább egy KAPOCS-iskolahálózati partnerintézménnyel, és biztosítja számára a szakmai támogatást (hospitálás lehetőségének biztosítása a fogadó intézményben,tanácsadás). A pályázat mellékleteként postai úton elküldi azon KAPOCS-iskolahálózati partnerintézmény(ek) nevét, címét, elérhetőségét, akivel a 2021/2022-es tanévben kapcsolatot tart és biztosítja a szakmai támogatást."},
]

export const KAP_ELMENYSULI_2021_ONCE_RENEW_REGISTRATION_GROUPS: {[key: string]: (keyof(KapElmenysuli2021OnceRenewRegistration))[]} = {
    "<u>Élménysuliként pályázók kritériumrendszere</u>:": ["check_o_1", "check_o_2", "check_o_3"],
}


export type KapElmenysuli2021TwiceRenewRegistration = {
    check_t_1: boolean;
    check_t_2: boolean;
    check_t_3: boolean;
}

export const KAP_ELMENYSULI_2021_TWICE_RENEW_REGISTRATION_CHECKBOXES: {key: keyof(KapElmenysuli2021TwiceRenewRegistration), name: string }[] = [
    {key: "check_t_1", name: "Az intézmény két tanévben az Élménysuli-címet elnyerte, és a Komplex Alapprogramot továbbra is megvalósítja"},
    {key: "check_t_2", name: "Vállalja, hogy legalább 3 tanévben tanévenként 2 szakmai napot (műhely, workshop) bármilyen témában megszervez és megvalósít, ahová  a  KAPOCS-iskolahálózat tagjait, valamint a KAP iránt érdeklődő iskolákat fogadja."},
    {key: "check_t_3", name: "Postai úton csatolja a pályázathoz a Nyilatkozatokat, mellyel igazolja az előző tanévre vállalt iskola mentorálását, illetve a szakmai nap megszervezését."},
]

export const KAP_ELMENYSULI_2021_TWICE_RENEW_REGISTRATION_GROUPS: {[key: string]: (keyof(KapElmenysuli2021TwiceRenewRegistration))[]} = {
    "<u>Kétszeres Élménysuliként az Örökös Élménysuli címre pályázók kritériumrendszere</u>:": ["check_t_1", "check_t_2", "check_t_3"],
}

type KAPElmenysuli2021RegisterPageProps = {
    match: match<{id: string}>,
    history: History
}

type KAPElmenysuli2021RegisterPageState = {
    registration?: IKapRegistration,
    mainRegistration?: KapRegistration,
    elmenysuliNewRegistration: KapElmenysuli2021NewRegistration,
    elmenysuliOnceRenewRegistration: KapElmenysuli2021OnceRenewRegistration,
    elmenysuliTwiceRenewRegistration: KapElmenysuli2021TwiceRenewRegistration,
    errors: string[]
}

export class KAPElmenysuli2021RegisterPage extends React.Component<KAPElmenysuli2021RegisterPageProps, KAPElmenysuli2021RegisterPageState> {

    constructor(props: KAPElmenysuli2021RegisterPageProps) {
        super(props);

        this.state = {
            elmenysuliNewRegistration: {
                check_n_1: false,
                check_n_2: false,
                check_n_3: false,
                check_n_4: false,
                check_n_5: false,
            },
            elmenysuliOnceRenewRegistration: {
                check_o_1: false,
                check_o_2: false,
                check_o_3: false,
            },
            elmenysuliTwiceRenewRegistration: {
                check_t_1: false,
                check_t_2: false,
                check_t_3: false,
            },
            errors: []
        }
    }

    async componentDidMount() {
        const registrations = await getRegistrations();
        if (registrations) {
            const registration = registrations.find(r => "" + r.id == this.props.match.params.id);
            if (registration) {
                this.setState({
                    registration: registration,
                    mainRegistration: JSON.parse(registration.registration)
                });
            }
        }
    }

    private onCheckElmenysuli2021New(key: keyof(KapElmenysuli2021NewRegistration), e: React.ChangeEvent<HTMLInputElement>) {
        this.state.elmenysuliNewRegistration[key] = e.target.checked;

        this.setState({
            elmenysuliNewRegistration: this.state.elmenysuliNewRegistration
        });
    }

    private onCheckElmenysuli2021OnceRenew(key: keyof(KapElmenysuli2021OnceRenewRegistration), e: React.ChangeEvent<HTMLInputElement>) {
        this.state.elmenysuliOnceRenewRegistration[key] = e.target.checked;

        this.setState({
            elmenysuliOnceRenewRegistration: this.state.elmenysuliOnceRenewRegistration
        });
    }
    
    private onCheckElmenysuli2021TwiceRenew(key: keyof(KapElmenysuli2021TwiceRenewRegistration), e: React.ChangeEvent<HTMLInputElement>) {
        this.state.elmenysuliTwiceRenewRegistration[key] = e.target.checked;

        this.setState({
            elmenysuliTwiceRenewRegistration: this.state.elmenysuliTwiceRenewRegistration
        });
    }
    
    private async onRegister() {
        if (!this.state.mainRegistration) return;

        const response = await updateRegistration(this.props.match.params.id, this.state.mainRegistration, null, null, Object.assign({}, this.state.elmenysuliNewRegistration, this.state.elmenysuliOnceRenewRegistration, this.state.elmenysuliTwiceRenewRegistration));

        if (!response.success) {
            alert(response.error || "Szerver oldali hiba történt");
            return;
        }

        this.props.history.push("/kap/elmenysuli_2021_print/" + this.props.match.params.id + "?dialog");
    }
    
    render() {
        if (!this.state.mainRegistration || !this.state.registration) {
            return null;
        }

        let previousElmenysuliRegistrationCount = 0;
        if (this.state.registration.elmenysuli_status_id == REGISTRATION_ACCEPTED_ID) previousElmenysuliRegistrationCount++;
        if (this.state.registration.elmenysuli_2020_status_id == REGISTRATION_ACCEPTED_ID) previousElmenysuliRegistrationCount++;
        
        return <div className="row kap-registration-form" style={{marginTop: "1em"}}>
            <div className="column small-12">
                <img src="/img/kap/eke_logo.png"/>
                <img src="/img/kap/kap_logo.png" style={{marginLeft: "1em"}}/>
                
                <h3 style={{margin: "1em 0"}}>
                    {previousElmenysuliRegistrationCount === 0 && "Köszöntjük az Élménysuli cím pályázat oldalán!"}
                    {previousElmenysuliRegistrationCount === 1 && "Köszöntjük az Élménysuli cím megújítása pályázat oldalán!"}
                    {previousElmenysuliRegistrationCount === 2 && "Köszöntjük az Örökös Élménysuli cím pályázat oldalán!"}
                </h3>

                <p>
                    Az Élménysuli olyan pályázat által elnyerhető cím, amelyet azon KAPOCS-iskolahálózat partnerintézményei nyernek el egy tanévre, akik a program elemeit mintaértékűen valósítják meg, és az újszerű módszerek elsajátítása érdekében igénybe veszik az SZTR által biztosított szakmai támogatást. A nyertes pályázók jogosultak lesznek az Élménysuli logójának és az Élménysuli megnevezés használatára.
                </p>

                <p>
                    Kérjük, jelölje be azokat az állításokat, amelyek igazak az ön intézményére! A pályázatot az intézmény törvényes képviselője tölti ki és nyilatkozik a kritériumok megvalósulásáról. Ebből a Beküld gomb lenyomásával a felület generál egy nyomtatható dokumentumot, amelyben az intézmény törvényes képviselője kijelenti és aláírásával igazolja, hogy az abban szereplő adatok és állítások a valóságnak megfelelnek, hitelesek és pontosak.
                </p>
                <p>
                    Mivel ön már bejelentkezett a saját profiljába ezért az intézmény adatait {this.state.registration.elmenysuli_status_id != REGISTRATION_ACCEPTED_ID ? "és a KAPOCS-iskolahálózat regisztrációjánál bejelölt kritériumokat": ""} a rendszer hozzágenerálja az elkészített dokumentumhoz.
                </p>

                <h4>
                    {
                        previousElmenysuliRegistrationCount === 0 && "A regisztrációs feltételeken túl az Élménysuli cím követelményei a következők:"
                    }
                    {
                        previousElmenysuliRegistrationCount === 1 && "A regisztrációs feltételeken túl az Élménysuli cím megújításának követelményei a következők:"
                    }
                    {
                        previousElmenysuliRegistrationCount === 2 && "A regisztrációs feltételeken túl az Örökös Élménysuli cím követelményei a következők:"
                    }
                </h4>
                <div style={{marginLeft: "3em"}}>

                    {
                        previousElmenysuliRegistrationCount === 0 && 
                        <KAPElmenysuli2021NewRequirementsEdit elmenysuliRegistration={this.state.elmenysuliNewRegistration} onChange={this.onCheckElmenysuli2021New.bind(this)} />
                    }
                    {
                        previousElmenysuliRegistrationCount === 1 && 
                        <KAPElmenysuli2021OnceRenewRequirementsEdit elmenysuliRegistration={this.state.elmenysuliOnceRenewRegistration} onChange={this.onCheckElmenysuli2021OnceRenew.bind(this)} />
                    }
                    {
                        previousElmenysuliRegistrationCount === 2 && 
                        <KAPElmenysuli2021TwiceRenewRequirementsEdit elmenysuliRegistration={this.state.elmenysuliTwiceRenewRegistration} onChange={this.onCheckElmenysuli2021TwiceRenew.bind(this)} />
                    }

                </div>

                {
                    this.state.errors.length > 0 &&
                    <div className="callout alert">
                        <h5></h5>
                        <ul>
                            {this.state.errors.map(e => <li>{e}</li>)}
                        </ul>
                    </div>
                }
                <br/>
                <button className="button primary" onClick={this.onRegister.bind(this)}>
                    Beküld  
                </button>

            </div>
        </div>;
    }

}

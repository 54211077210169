import Axios from 'axios';
import { IKapRegistration } from './component/kap/KAPRegistrationListPage';

export async function login(email: string, password: string) {
    var params = new URLSearchParams();
    params.append('path', "/login");
    params.append('email', email);
    params.append('password', password);

    const response = await Axios.post("/api.php", params);
    return response.data;
}

export async function register(email: string, password: string) {
    var params = new URLSearchParams();
    params.append('path', "/register");
    params.append('email', email);
    params.append('password', password);

    const response = await Axios.post("/api.php", params);
    return response.data;
}

export async function logout() {
    var params = new URLSearchParams();
    params.append('path', "/logout");
    
    const response = await Axios.post("/api.php", params);
    if (response.data) return response.data.user;
    return null;
}

export async function getMe() {
    var params = new URLSearchParams();
    params.append('path', "/me");
    
    const response = await Axios.post("/api.php", params);
    if (response.data) return response.data.user;
    return null;
}

export async function registerKAPOCS(registration: {}) {
    var params = new URLSearchParams();
    params.append('path', "/kap/register");
    params.append('registration', JSON.stringify(registration));
    
    const response = await Axios.post("/api.php", params);
    return response.data;
}

export async function updateRegistration(id: string, registration: {}, elmenysuli: {} | null, elmenysuli2020: {} | null = null, elmenysuli2021: {} | null = null, elmenysuli2022: {} | null = null, elmenysuli2023: {} | null = null, elmenysuli2024: {} | null = null) {
    var params = new URLSearchParams();
    params.append('path', "/kap/update");
    params.append('id', id);
    params.append('registration', JSON.stringify(registration));
    if (elmenysuli) {
        params.append('elmenysuli', JSON.stringify(elmenysuli));
    }
    if (elmenysuli2020) {
        params.append('elmenysuli_2020', JSON.stringify(elmenysuli2020));
    }
    if (elmenysuli2021) {
        params.append('elmenysuli_2021', JSON.stringify(elmenysuli2021));
    }
    if (elmenysuli2022) {
        params.append('elmenysuli_2022', JSON.stringify(elmenysuli2022));
    }
    if (elmenysuli2023) {
        params.append('elmenysuli_2023', JSON.stringify(elmenysuli2023));
    }
    if (elmenysuli2024) {
        params.append('elmenysuli_2024', JSON.stringify(elmenysuli2024));
    }
    
    const response = await Axios.post("/api.php", params);
    return response.data;
}

export async function acceptRegistration(id: string) {
    var params = new URLSearchParams();
    params.append('path', "/kap/accept_registration");
    params.append('id', id);
    
    const response = await Axios.post("/api.php", params);
    return response.data;
}

export async function rejectRegistration(id: string) {
    var params = new URLSearchParams();
    params.append('path', "/kap/reject_registration");
    params.append('id', id);
    
    const response = await Axios.post("/api.php", params);
    return response.data;
}

export async function acceptElmenysuli(id: string) {
    var params = new URLSearchParams();
    params.append('path', "/kap/accept_elmenysuli");
    params.append('id', id);
    
    const response = await Axios.post("/api.php", params);
    return response.data;
}

export async function rejectElmenysuli(id: string) {
    var params = new URLSearchParams();
    params.append('path', "/kap/reject_elmenysuli");
    params.append('id', id);
    
    const response = await Axios.post("/api.php", params);
    return response.data;
}

export async function retificateElmenysuli(id: string) {
    var params = new URLSearchParams();
    params.append('path', "/kap/retificate_elmenysuli");
    params.append('id', id);
    
    const response = await Axios.post("/api.php", params);
    return response.data;
}

export async function acceptElmenysuli2020(id: string) {
    var params = new URLSearchParams();
    params.append('path', "/kap/accept_elmenysuli_2020");
    params.append('id', id);
    
    const response = await Axios.post("/api.php", params);
    return response.data;
}

export async function rejectElmenysuli2020(id: string) {
    var params = new URLSearchParams();
    params.append('path', "/kap/reject_elmenysuli_2020");
    params.append('id', id);
    
    const response = await Axios.post("/api.php", params);
    return response.data;
}

export async function retificateElmenysuli2020(id: string) {
    var params = new URLSearchParams();
    params.append('path', "/kap/retificate_elmenysuli_2020");
    params.append('id', id);
    
    const response = await Axios.post("/api.php", params);
    return response.data;
}



export async function acceptElmenysuli2021(id: string) {
    var params = new URLSearchParams();
    params.append('path', "/kap/accept_elmenysuli_2021");
    params.append('id', id);
    
    const response = await Axios.post("/api.php", params);
    return response.data;
}

export async function rejectElmenysuli2021(id: string) {
    var params = new URLSearchParams();
    params.append('path', "/kap/reject_elmenysuli_2021");
    params.append('id', id);
    
    const response = await Axios.post("/api.php", params);
    return response.data;
}

export async function retificateElmenysuli2021(id: string) {
    var params = new URLSearchParams();
    params.append('path', "/kap/retificate_elmenysuli_2021");
    params.append('id', id);
    
    const response = await Axios.post("/api.php", params);
    return response.data;
}


export async function acceptElmenysuli2022(id: string) {
    var params = new URLSearchParams();
    params.append('path', "/kap/accept_elmenysuli_2022");
    params.append('id', id);
    
    const response = await Axios.post("/api.php", params);
    return response.data;
}

export async function rejectElmenysuli2022(id: string) {
    var params = new URLSearchParams();
    params.append('path', "/kap/reject_elmenysuli_2022");
    params.append('id', id);
    
    const response = await Axios.post("/api.php", params);
    return response.data;
}

export async function retificateElmenysuli2022(id: string) {
    var params = new URLSearchParams();
    params.append('path', "/kap/retificate_elmenysuli_2022");
    params.append('id', id);
    
    const response = await Axios.post("/api.php", params);
    return response.data;
}

export async function acceptElmenysuli2023(id: string) {
    var params = new URLSearchParams();
    params.append('path', "/kap/accept_elmenysuli_2023");
    params.append('id', id);
    
    const response = await Axios.post("/api.php", params);
    return response.data;
}

export async function rejectElmenysuli2023(id: string) {
    var params = new URLSearchParams();
    params.append('path', "/kap/reject_elmenysuli_2023");
    params.append('id', id);
    
    const response = await Axios.post("/api.php", params);
    return response.data;
}

export async function retificateElmenysuli2023(id: string) {
    var params = new URLSearchParams();
    params.append('path', "/kap/retificate_elmenysuli_2023");
    params.append('id', id);
    
    const response = await Axios.post("/api.php", params);
    return response.data;
}

export async function acceptElmenysuli2024(id: string) {
    var params = new URLSearchParams();
    params.append('path', "/kap/accept_elmenysuli_2024");
    params.append('id', id);
    
    const response = await Axios.post("/api.php", params);
    return response.data;
}

export async function rejectElmenysuli2024(id: string) {
    var params = new URLSearchParams();
    params.append('path', "/kap/reject_elmenysuli_2024");
    params.append('id', id);
    
    const response = await Axios.post("/api.php", params);
    return response.data;
}

export async function retificateElmenysuli2024(id: string) {
    var params = new URLSearchParams();
    params.append('path', "/kap/retificate_elmenysuli_2024");
    params.append('id', id);
    
    const response = await Axios.post("/api.php", params);
    return response.data;
}

export async function deleteRegistration(id: string) {
    var params = new URLSearchParams();
    params.append('path', "/kap/delete_registration");
    params.append('id', id);
    
    const response = await Axios.post("/api.php", params);
    return response.data;
}

export async function getRegistrations(): Promise<IKapRegistration[] | null> {
    var params = new URLSearchParams();
    params.append('path', "/kap/get_registrations");
    
    const response = await Axios.post("/api.php", params);
    if (response.data) return response.data.registrations;
    return null;
}

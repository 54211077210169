import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { KAPRegisterPage, KapRegistration } from './component/kap/KAPRegisterPage';
import { KAPPrintRegistrationPage } from './component/kap/KAPPrintRegistrationPage';
import { KAPHeader } from './component/kap/KAPHeader';
import { KAPFooter } from './component/kap/KAPFooter';
import { KAPLoginPage } from './component/kap/KAPLoginPage';
import { me } from './framework/server/Auth';
import { KAPRegistrationListPage } from './component/kap/KAPRegistrationListPage';
import { KAPElmenysuliRegisterPage } from './component/kap/KAPElmenysuliRegisterPage';
import { KAPPrintElmenysuliPage } from './component/kap/KAPPrintElmenysuliPage';
import { KAPElmenysuli2020RegisterPage } from './component/kap/KAPElmenysuli2020RegisterPage';
import { KAPPrintElmenysuli2020Page } from './component/kap/KAPPrintElmenysuli2020Page';
import { KAPDetailsPage } from './component/kap/KAPRegistrationDetails';
import { KAPEditPage } from './component/kap/KAPEditPage';
import { RegisterPage } from './component/kap/RegisterPage';
import { KAPElmenysuli2021RegisterPage } from './component/kap/KAPElmenysuli2021RegisterPage';
import { KAPPrintElmenysuli2021Page } from './component/kap/KAPPrintElmenysuli2021Page';
import { KAPElmenysuli2022RegisterPage } from './component/kap/KAPElmenysuli2022RegisterPage';
import { KAPPrintElmenysuli2022Page } from './component/kap/KAPPrintElmenysuli2022Page';
import { KAPElmenysuli2023RegisterPage } from './component/kap/KAPElmenysuli2023RegisterPage';
import { KAPPrintElmenysuli2023Page } from './component/kap/KAPPrintElmenysuli2023Page';
import { KAPElmenysuli2024RegisterPage } from './component/kap/KAPElmenysuli2024RegisterPage';
import { KAPPrintElmenysuli2024Page } from './component/kap/KAPPrintElmenysuli2024Page';

class App extends Component<{}, {registration?: KapRegistration}> {
  constructor(props: any) {
    super(props);

    this.state = {};

    app = this;
  }
/*
  public setRegistration(registration?: KapRegistration) {
    this.setState({
      registration
    })
    window.scrollTo(0, 0);
  }

  public getRegistration() {
    return this.state.registration;
  }
*/
  render() {
    var defaultPage;

    if (!me) {
      defaultPage = KAPLoginPage;
    } else {
        if (location.hostname === "www.regisztracio.komplexalapprogram.hu") {
          defaultPage = KAPRegisterPage;
        } else {
          defaultPage = KAPRegistrationListPage;
        }
    }

    return (
      <BrowserRouter>
        <div>
          <KAPHeader />
          <div style={{minHeight: "70vh"}}>
            <Switch>
              <Route path="/" exact component={defaultPage} />
              
              <Route path="/login" component={KAPLoginPage} />
              <Route path="/register" component={RegisterPage} />

              <Route path="/kap/register" component={KAPRegisterPage} />

              {
                me ?
                <>
                  <Route path="/kap/list" component={KAPRegistrationListPage} />
                  <Route path="/kap/print/:id" component={KAPPrintRegistrationPage} />

                  <Route path="/kap/details/:id" component={KAPDetailsPage} />

                  <Route path="/kap/modify_base/:id" component={KAPEditPage} />
                  <Route path="/kap/modify_kapocs/:id" component={KAPEditPage} />

                  <Route path="/kap/elmenysuli/:id" component={KAPElmenysuliRegisterPage} />
                  <Route path="/kap/elmenysuli_print/:id" component={KAPPrintElmenysuliPage} />
                  <Route path="/kap/modify_elmenysuli/:id" component={KAPEditPage} />

                  <Route path="/kap/elmenysuli_2020/:id" component={KAPElmenysuli2020RegisterPage} />
                  <Route path="/kap/elmenysuli_2020_print/:id" component={KAPPrintElmenysuli2020Page} />
                  <Route path="/kap/modify_elmenysuli_2020/:id" component={KAPEditPage} />

                  <Route path="/kap/elmenysuli_2021/:id" component={KAPElmenysuli2021RegisterPage} />
                  <Route path="/kap/elmenysuli_2021_print/:id" component={KAPPrintElmenysuli2021Page} />
                  <Route path="/kap/modify_elmenysuli_2021/:id" component={KAPEditPage} />

                  <Route path="/kap/elmenysuli_2022/:id" component={KAPElmenysuli2022RegisterPage} />
                  <Route path="/kap/elmenysuli_2022_print/:id" component={KAPPrintElmenysuli2022Page} />
                  <Route path="/kap/modify_elmenysuli_2022/:id" component={KAPEditPage} />

                  <Route path="/kap/elmenysuli_2023/:id" component={KAPElmenysuli2023RegisterPage} />
                  <Route path="/kap/elmenysuli_2023_print/:id" component={KAPPrintElmenysuli2023Page} />
                  <Route path="/kap/modify_elmenysuli_2023/:id" component={KAPEditPage} />

                  <Route path="/kap/elmenysuli_2024/:id" component={KAPElmenysuli2024RegisterPage} />
                  <Route path="/kap/elmenysuli_2024_print/:id" component={KAPPrintElmenysuli2024Page} />
                  <Route path="/kap/modify_elmenysuli_2024/:id" component={KAPEditPage} />
                </>
                :
                <Route path="/" component={defaultPage} />
              }
            </Switch>
          </div>

          <KAPFooter />
        </div>
      </BrowserRouter>
    );
  }
}

export var app: App;

export default App;

import * as React from 'react';
import { KapRegistration } from './KAPRegisterPage';
import { getRegistrations, updateRegistration } from '../../KAPServer';
import { match } from 'react-router';
import { History } from 'history';
import { KAPElmenysuli2022NewRequirementsEdit, KAPElmenysuli2022OnceRenewRequirementsEdit, KAPElmenysuli2022TwiceRenewRequirementsEdit } from './KAPComponents';
import { REGISTRATION_ACCEPTED_ID, IKapRegistration } from './KAPRegistrationListPage';

export type KapElmenysuli2022NewRegistration = {
    check_n_1: boolean;
    check_n_2: boolean;
    check_n_3: boolean;
    check_n_4: boolean;
    check_n_5: boolean;
}

export const KAP_ELMENYSULI_2022_NEW_REGISTRATION_CHECKBOXES: {key: keyof(KapElmenysuli2022NewRegistration), name: string }[] = [
    {key: "check_n_1", name: "Az intézmény tantestülete legalább 50%-a elvégezte a Komplex Alapprogram legalább négy (két alapozó és két alprogrami) képzését."},
    {key: "check_n_2", name: "Az intézmény legalább egy éve a Komplex Alapprogramot megvalósítja a regisztrációkor adott válaszok alapján."},
    {key: "check_n_3", name: "Az intézményvezető nyilatkozik arról, hogy a következő évi munkatervében megjelöli a DFHT tanulási-tanítási stratégia, a Ráhangolódást biztosító foglalkozások megvalósításainak lehetőségeit és min. 2 alprogram alkalmazási lehetőségeit."},
    {key: "check_n_4", name: "A KAP bevezetésével érintett évfolyamokon a tanmeneteket a KAP céljaihoz igazítva készítik el, megjelölve bennük az alprogrami kapcsolódási pontokat is (Komplex órák) a DFHT tanítási-tanulási stratégiát alkalmazó tanórák megjelölése mellett."},
    {key: "check_n_5", name: "Vállalja, hogy a 2022/2023-as tanévben egy-egy szakmai napot (műhely, workshop) önállóan megszervez a tanév során és megvalósít, vagy intézményeknek alprogram(ok)hoz versenyt valósít meg. A program megszervezését egy nyilatkozattal hitelesíti, melyet a pályázat mellé csatolunk."},
]

export const KAP_ELMENYSULI_2022_NEW_REGISTRATION_GROUPS: {[key: string]: (keyof(KapElmenysuli2022NewRegistration))[]} = {
    "<b>A nem Élménysuliként pályázók kritériumrendszere</b>:": [],
    "A pályázat értékelése során a KAPOCS-iskolahálózat regisztrációs feltételein túl, az Élménysuli címhez szükséges kritériumoknak való megfelelés vizsgálata történik meg. Az Élménysuli követelmények a következők:": [],
    "<u>A pályázat benyújtásának tanévében</u>:": ["check_n_1", "check_n_2", "check_n_3", "check_n_4", "check_n_5"],
}

export type KapElmenysuli2022OnceRenewRegistration = {
    check_o_1: boolean;
    check_o_2: boolean;
}

export const KAP_ELMENYSULI_2022_ONCE_RENEW_REGISTRATION_CHECKBOXES: {key: keyof(KapElmenysuli2022OnceRenewRegistration), name: string }[] = [
    {key: "check_o_1", name: "Az egyszeres Élménysuli kritériumait teljesíti."},
    {key: "check_o_2", name: "Valamint vállalja, hogy a 2022/2023-as tanévben egy szakmai napot (műhely, workshop) önállóan megszervez a tanév során és megvalósít, és intézményeknek alprogram(ok)hoz versenyt valósít meg. A program megszervezését egy nyilatkozattal hitelesíti, melyet a pályázat mellé csatolunk."},
]

export const KAP_ELMENYSULI_2022_ONCE_RENEW_REGISTRATION_GROUPS: {[key: string]: (keyof(KapElmenysuli2022OnceRenewRegistration))[]} = {
    "<u>Élménysuliként pályázók kritériumrendszere</u><br>Amely intézmény az „Élménysuli” címét szeretné megújítani, az alábbi követelményeknek kell megfelelnie:": ["check_o_1", "check_o_2"],
}


export type KapElmenysuli2022TwiceRenewRegistration = {
    check_t_1: boolean;
    check_t_2: boolean;
}

export const KAP_ELMENYSULI_2022_TWICE_RENEW_REGISTRATION_CHECKBOXES: {key: keyof(KapElmenysuli2022TwiceRenewRegistration), name: string }[] = [
    {key: "check_t_1", name: "Vállalja, hogy legalább 3 tanéven át egy szakmai napot önállóan megszervez és megvalósít, valamint az intézményeknek alprogram(ok)hoz versenyt valósít meg. A programok megszervezését nyilatkozatokkal hitelesíti, melyet a pályázat mellé csatolunk."},
    {key: "check_t_2", name: "Továbbá mentorál olyan intézményt, aki most ismerkedik a Komplex Alapprogrammal és segíti, hogy a tanév végével KAPOCS-iskolahálózati tag legyen. A mentorálást egy nyilatkozattal hitelesíti elektronikusan és postai úton is, melyet a pályázat mellé csatolunk."},
]

export const KAP_ELMENYSULI_2022_TWICE_RENEW_REGISTRATION_GROUPS: {[key: string]: (keyof(KapElmenysuli2022TwiceRenewRegistration))[]} = {
    "<u>Kétszeres Élménysuliként az Örökös Élménysuli címre pályázók kritériumrendszere</u><br>Amely intézmény az „Élménysuli” címét másodszor szeretné megújítani, az alábbi követelményeknek kell megfelelnie:": ["check_t_1", "check_t_2"],
}

type KAPElmenysuli2022RegisterPageProps = {
    match: match<{id: string}>,
    history: History
}

type KAPElmenysuli2022RegisterPageState = {
    registration?: IKapRegistration,
    mainRegistration?: KapRegistration,
    elmenysuliNewRegistration: KapElmenysuli2022NewRegistration,
    elmenysuliOnceRenewRegistration: KapElmenysuli2022OnceRenewRegistration,
    elmenysuliTwiceRenewRegistration: KapElmenysuli2022TwiceRenewRegistration,
    errors: string[]
}

export class KAPElmenysuli2022RegisterPage extends React.Component<KAPElmenysuli2022RegisterPageProps, KAPElmenysuli2022RegisterPageState> {

    constructor(props: KAPElmenysuli2022RegisterPageProps) {
        super(props);

        this.state = {
            elmenysuliNewRegistration: {
                check_n_1: false,
                check_n_2: false,
                check_n_3: false,
                check_n_4: false,
                check_n_5: false,
            },
            elmenysuliOnceRenewRegistration: {
                check_o_1: false,
                check_o_2: false,
            },
            elmenysuliTwiceRenewRegistration: {
                check_t_1: false,
                check_t_2: false,
            },
            errors: []
        }
    }

    async componentDidMount() {
        const registrations = await getRegistrations();
        if (registrations) {
            const registration = registrations.find(r => "" + r.id == this.props.match.params.id);
            if (registration) {
                this.setState({
                    registration: registration,
                    mainRegistration: JSON.parse(registration.registration)
                });
            }
        }
    }

    private onCheckElmenysuli2022New(key: keyof(KapElmenysuli2022NewRegistration), e: React.ChangeEvent<HTMLInputElement>) {
        this.state.elmenysuliNewRegistration[key] = e.target.checked;

        this.setState({
            elmenysuliNewRegistration: this.state.elmenysuliNewRegistration
        });
    }

    private onCheckElmenysuli2022OnceRenew(key: keyof(KapElmenysuli2022OnceRenewRegistration), e: React.ChangeEvent<HTMLInputElement>) {
        this.state.elmenysuliOnceRenewRegistration[key] = e.target.checked;

        this.setState({
            elmenysuliOnceRenewRegistration: this.state.elmenysuliOnceRenewRegistration
        });
    }
    
    private onCheckElmenysuli2022TwiceRenew(key: keyof(KapElmenysuli2022TwiceRenewRegistration), e: React.ChangeEvent<HTMLInputElement>) {
        this.state.elmenysuliTwiceRenewRegistration[key] = e.target.checked;

        this.setState({
            elmenysuliTwiceRenewRegistration: this.state.elmenysuliTwiceRenewRegistration
        });
    }
    
    private async onRegister() {
        if (!this.state.mainRegistration) return;

        const response = await updateRegistration(this.props.match.params.id, this.state.mainRegistration, null, null, null, Object.assign({}, this.state.elmenysuliNewRegistration, this.state.elmenysuliOnceRenewRegistration, this.state.elmenysuliTwiceRenewRegistration));

        if (!response.success) {
            alert(response.error || "Szerver oldali hiba történt");
            return;
        }

        this.props.history.push("/kap/elmenysuli_2022_print/" + this.props.match.params.id + "?dialog");
    }
    
    render() {
        if (!this.state.mainRegistration || !this.state.registration) {
            return null;
        }

        let previousElmenysuliRegistrationCount = 0;
        if (this.state.registration.elmenysuli_status_id == REGISTRATION_ACCEPTED_ID) previousElmenysuliRegistrationCount++;
        if (this.state.registration.elmenysuli_2020_status_id == REGISTRATION_ACCEPTED_ID) previousElmenysuliRegistrationCount++;
        if (this.state.registration.elmenysuli_2021_status_id == REGISTRATION_ACCEPTED_ID) previousElmenysuliRegistrationCount++;
        
        return <div className="row kap-registration-form" style={{marginTop: "1em"}}>
            <div className="column small-12">
                <img src="/img/kap/eke_logo.png"/>
                <img src="/img/kap/kap_logo.png" style={{marginLeft: "1em"}}/>
                
                <h3 style={{margin: "1em 0"}}>
                    {previousElmenysuliRegistrationCount === 0 && "Köszöntjük az Élménysuli cím pályázat oldalán!"}
                    {previousElmenysuliRegistrationCount === 1 && "Köszöntjük az Élménysuli cím megújítása pályázat oldalán!"}
                    {previousElmenysuliRegistrationCount >= 2 && "Köszöntjük az Örökös Élménysuli cím pályázat oldalán!"}
                </h3>

                <p>
                    Az Élménysuli olyan pályázat által elnyerhető cím, amelyet azon KAPOCS-iskolahálózat partnerintézményei nyernek el egy tanévre, akik a program elemeit mintaértékűen valósítják meg, és az újszerű módszerek elsajátítása érdekében igénybe veszik az SZTR által biztosított szakmai támogatást. A nyertes pályázók jogosultak lesznek az Élménysuli logójának és az Élménysuli megnevezés használatára.
                </p>

                <p>
                    Kérjük, jelölje be azokat az állításokat, amelyek igazak az ön intézményére! A pályázatot az intézmény törvényes képviselője tölti ki és nyilatkozik a kritériumok megvalósulásáról. Ebből a Beküld gomb lenyomásával a felület generál egy nyomtatható dokumentumot, amelyben az intézmény törvényes képviselője kijelenti és aláírásával igazolja, hogy az abban szereplő adatok és állítások a valóságnak megfelelnek, hitelesek és pontosak.
                </p>
                <p>
                    Mivel ön már bejelentkezett a saját profiljába ezért az intézmény adatait {this.state.registration.elmenysuli_status_id != REGISTRATION_ACCEPTED_ID ? "és a KAPOCS-iskolahálózat regisztrációjánál bejelölt kritériumokat": ""} a rendszer hozzágenerálja az elkészített dokumentumhoz.
                </p>

                <h4>
                    {
                        previousElmenysuliRegistrationCount === 0 && "A regisztrációs feltételeken túl az Élménysuli cím követelményei a következők:"
                    }
                    {
                        previousElmenysuliRegistrationCount === 1 && "A regisztrációs feltételeken túl az Élménysuli cím megújításának követelményei a következők:"
                    }
                    {
                        previousElmenysuliRegistrationCount >= 2 && "A regisztrációs feltételeken túl az Örökös Élménysuli cím követelményei a következők:"
                    }
                </h4>
                <div style={{marginLeft: "3em"}}>

                    {
                        previousElmenysuliRegistrationCount === 0 && 
                        <KAPElmenysuli2022NewRequirementsEdit elmenysuliRegistration={this.state.elmenysuliNewRegistration} onChange={this.onCheckElmenysuli2022New.bind(this)} />
                    }
                    {
                        previousElmenysuliRegistrationCount === 1 && 
                        <KAPElmenysuli2022OnceRenewRequirementsEdit elmenysuliRegistration={this.state.elmenysuliOnceRenewRegistration} onChange={this.onCheckElmenysuli2022OnceRenew.bind(this)} />
                    }
                    {
                        previousElmenysuliRegistrationCount >= 2 && 
                        <KAPElmenysuli2022TwiceRenewRequirementsEdit elmenysuliRegistration={this.state.elmenysuliTwiceRenewRegistration} onChange={this.onCheckElmenysuli2022TwiceRenew.bind(this)} />
                    }

                </div>

                <div>
                    {
                        previousElmenysuliRegistrationCount === 0 && <>
                            <p>Ezen feltételek teljesítése esetén az intézmény egy tanévig az <b>„Élménysuli”</b> címet elnyeri.</p>
                            <p>Aki nem teljesíti a feltételeket és nem küldi be a nyilatkozatot 2023. április 30-ig postai úton (az utolsó postára adási nap), a következő évben nem pályázhat Élménysuli címre. </p>
                        </>
                    }
                    {
                        previousElmenysuliRegistrationCount === 1 && <>
                            <p>Ezen feltételek teljesítése esetén az intézmény egy tanévig a <b>„Kétszeres Élménysuli”</b> címet elnyeri. </p>
                            <p>Aki nem teljesíti a feltételeket és nem küldi be a nyilatkozatot 2023. április 30-ig postai úton (az utolsó postára adási nap), a következő évben nem pályázhat Élménysuli címre.</p>
                        </>
                    }
                    {
                        previousElmenysuliRegistrationCount >= 2 && <>
                            <p>Ezen feltételek teljesítése esetén az intézmény az <b>„Örökös Élménysuli”</b> címet elnyeri. </p>
                            <p>A nyilatkozatokat minden év április 30-ig postai úton (az utolsó postára adási nap) köteles megküldeni az Örökös Élménysuli a kiíró részére.</p>
                        </>
                    }

                </div>

                {
                    this.state.errors.length > 0 &&
                    <div className="callout alert">
                        <h5></h5>
                        <ul>
                            {this.state.errors.map(e => <li>{e}</li>)}
                        </ul>
                    </div>
                }
                <br/>
                <button className="button primary" onClick={this.onRegister.bind(this)}>
                    Beküld  
                </button>

            </div>
        </div>;
    }

}

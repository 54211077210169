import * as React from 'react';
import { IKapRegistration, REGISTRATION_ACCEPTED_ID, REGISTRATION_REJECTED_ID, REGISTRATION_PENDING_ID, REGISTRATION_RETIFICATION_ID } from './KAPRegistrationListPage';
import { match } from 'react-router';
import { getRegistrations, acceptRegistration, acceptElmenysuli, rejectRegistration, rejectElmenysuli, retificateElmenysuli, deleteRegistration, rejectElmenysuli2020, retificateElmenysuli2020, acceptElmenysuli2020, acceptElmenysuli2021, rejectElmenysuli2021, retificateElmenysuli2021, acceptElmenysuli2022, rejectElmenysuli2022, retificateElmenysuli2022, acceptElmenysuli2023, rejectElmenysuli2023, retificateElmenysuli2023, acceptElmenysuli2024, rejectElmenysuli2024, retificateElmenysuli2024 } from '../../KAPServer';
import { KAPBaseDataShow, KAPElmenysuliRequirementsShow, KAPRequirementsShow, KAPElmenysuli2020RequirementsShow, KAPElmenysuli2021RequirementsShow, KAPElmenysuli2022RequirementsShow, KAPElmenysuli2023RequirementsShow, KAPElmenysuli2024RequirementsShow } from './KAPComponents';
import { History } from 'history';

type KAPDetailsPageProps = {
    match: match<{id: string}>;
    history: History
}

type KAPDetailsPageState = {
    registration?: IKapRegistration;
}

export class KAPDetailsPage extends React.Component<KAPDetailsPageProps, KAPDetailsPageState> {

    constructor(props: any) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    componentDidUpdate(prevProps: KAPDetailsPageProps) {
        if (this.props.match.params.id != prevProps.match.params.id) {
            this.reloadAsync();
        }
    }

    async reloadAsync() {
        const registrations = await getRegistrations();
        if (registrations) {
            const registration = registrations.find(r => "" + r.id == this.props.match.params.id);
            this.setState({
                registration
            });
        }
    }

    private async onAcceptRegistration() {
        await acceptRegistration("" + this.state.registration!.id);
        this.reloadAsync();
    }

    private async onRejectRegistration() {
        if (!confirm("Biztosan elutasítja?")) return;
        await rejectRegistration("" + this.state.registration!.id);
        this.reloadAsync();
    }

    private async onAcceptElmenysuli() {
        await acceptElmenysuli("" + this.state.registration!.id);
        this.reloadAsync();
    }

    private async onRejectElmenysuli() {
        if (!confirm("Biztosan elutasítja?")) return;

        await rejectElmenysuli("" + this.state.registration!.id);
        this.reloadAsync();
    }

    private async onRetificateElmenysuli() {
        if (!confirm("Biztosan hiánypótlás állapotba állítja?")) return;

        await retificateElmenysuli("" + this.state.registration!.id);
        this.reloadAsync();
    }

    private async onAcceptElmenysuli2020() {
        await acceptElmenysuli2020("" + this.state.registration!.id);
        this.reloadAsync();
    }

    private async onRejectElmenysuli2020() {
        if (!confirm("Biztosan elutasítja?")) return;

        await rejectElmenysuli2020("" + this.state.registration!.id);
        this.reloadAsync();
    }

    private async onRetificateElmenysuli2020() {
        if (!confirm("Biztosan hiánypótlás állapotba állítja?")) return;

        await retificateElmenysuli2020("" + this.state.registration!.id);
        this.reloadAsync();
    }



    private async onAcceptElmenysuli2021() {
        await acceptElmenysuli2021("" + this.state.registration!.id);
        this.reloadAsync();
    }

    private async onRejectElmenysuli2021() {
        if (!confirm("Biztosan elutasítja?")) return;

        await rejectElmenysuli2021("" + this.state.registration!.id);
        this.reloadAsync();
    }

    private async onRetificateElmenysuli2021() {
        if (!confirm("Biztosan hiánypótlás állapotba állítja?")) return;

        await retificateElmenysuli2021("" + this.state.registration!.id);
        this.reloadAsync();
    }

    private async onAcceptElmenysuli2022() {
        await acceptElmenysuli2022("" + this.state.registration!.id);
        this.reloadAsync();
    }

    private async onRejectElmenysuli2022() {
        if (!confirm("Biztosan elutasítja?")) return;

        await rejectElmenysuli2022("" + this.state.registration!.id);
        this.reloadAsync();
    }

    private async onRetificateElmenysuli2022() {
        if (!confirm("Biztosan hiánypótlás állapotba állítja?")) return;

        await retificateElmenysuli2022("" + this.state.registration!.id);
        this.reloadAsync();
    }

    private async onAcceptElmenysuli2023() {
        await acceptElmenysuli2023("" + this.state.registration!.id);
        this.reloadAsync();
    }

    private async onRejectElmenysuli2023() {
        if (!confirm("Biztosan elutasítja?")) return;

        await rejectElmenysuli2023("" + this.state.registration!.id);
        this.reloadAsync();
    }

    private async onRetificateElmenysuli2023() {
        if (!confirm("Biztosan hiánypótlás állapotba állítja?")) return;

        await retificateElmenysuli2023("" + this.state.registration!.id);
        this.reloadAsync();
    }

    private async onAcceptElmenysuli2024() {
        await acceptElmenysuli2024("" + this.state.registration!.id);
        this.reloadAsync();
    }

    private async onRejectElmenysuli2024() {
        if (!confirm("Biztosan elutasítja?")) return;

        await rejectElmenysuli2024("" + this.state.registration!.id);
        this.reloadAsync();
    }

    private async onRetificateElmenysuli2024() {
        if (!confirm("Biztosan hiánypótlás állapotba állítja?")) return;

        await retificateElmenysuli2024("" + this.state.registration!.id);
        this.reloadAsync();
    }

    private async onDelete() {
        if (!confirm("Biztosan törli az iskolát?")) return;

        await deleteRegistration("" + this.state.registration!.id);

        this.props.history.push("/kap/list");
    }

    render() {
        const kapRegistration = this.state.registration;

        if (!kapRegistration) return null;

        const registration = JSON.parse(kapRegistration.registration);
        const elmenysuli = kapRegistration.elmenysuli ? JSON.parse(kapRegistration.elmenysuli) : null;
        const elmenysuli2020 = kapRegistration.elmenysuli_2020 ? JSON.parse(kapRegistration.elmenysuli_2020) : null;
        const elmenysuli2021 = kapRegistration.elmenysuli_2021 ? JSON.parse(kapRegistration.elmenysuli_2021) : null;
        const elmenysuli2022 = kapRegistration.elmenysuli_2022 ? JSON.parse(kapRegistration.elmenysuli_2022) : null;
        const elmenysuli2023 = kapRegistration.elmenysuli_2023 ? JSON.parse(kapRegistration.elmenysuli_2023) : null;
        const elmenysuli2024 = kapRegistration.elmenysuli_2024 ? JSON.parse(kapRegistration.elmenysuli_2024) : null;
        
        let registrationAccepted = <span className="label warning">KAPOCS regisztráció még nincs elfogadva</span>;
        if(kapRegistration.registration_status_id == REGISTRATION_ACCEPTED_ID) {
            registrationAccepted = <span className="label success">✔ KAPOCS regisztráció elfogadva</span>
        } else if(kapRegistration.registration_status_id == REGISTRATION_REJECTED_ID) {
            registrationAccepted = <span className="label alert">❌ KAPOCS regisztráció elutasítva</span>
        }

        let elmenysuliAccepted = <span className="label secondary">2019-Még nem pályázott élménysulira</span>;
        if (kapRegistration.elmenysuli) {
            if(kapRegistration.elmenysuli_status_id == REGISTRATION_PENDING_ID) {
                elmenysuliAccepted = <span className="label warning">2019-Élménysuli pályázat még nincs elfogadva</span>
            } else if(kapRegistration.elmenysuli_status_id == REGISTRATION_ACCEPTED_ID) {
                elmenysuliAccepted = <span className="label success">✔ 2019-Élménysuli pályázat elfogadva</span>
            } else if(kapRegistration.elmenysuli_status_id == REGISTRATION_REJECTED_ID) {
                elmenysuliAccepted = <span className="label alert">❌ 2019-Élménysuli pályázat elutasítva</span>
            } else if(kapRegistration.elmenysuli_status_id == REGISTRATION_RETIFICATION_ID) {
                elmenysuliAccepted = <span className="label warning">❌ 2019-Hiánypótlás</span>
            }
        }
        
        let elmenysuli2020Accepted = <span className="label secondary">2020-Még nem pályázott élménysulira</span>;
        if (kapRegistration.elmenysuli_2020) {
            if(kapRegistration.elmenysuli_2020_status_id == REGISTRATION_PENDING_ID) {
                elmenysuli2020Accepted = <span className="label warning">2020-Élménysuli pályázat még nincs elfogadva</span>
            } else if(kapRegistration.elmenysuli_2020_status_id == REGISTRATION_ACCEPTED_ID) {
                elmenysuli2020Accepted = <span className="label success">✔ 2020-Élménysuli pályázat elfogadva</span>
            } else if(kapRegistration.elmenysuli_2020_status_id == REGISTRATION_REJECTED_ID) {
                elmenysuli2020Accepted = <span className="label alert">❌ 2020-Élménysuli pályázat elutasítva</span>
            } else if(kapRegistration.elmenysuli_2020_status_id == REGISTRATION_RETIFICATION_ID) {
                elmenysuli2020Accepted = <span className="label warning">❌ 2020-Hiánypótlás</span>
            }
        }
        
        let elmenysuli2021Accepted = <span className="label secondary">2021-Még nem pályázott élménysulira</span>;
        if (kapRegistration.elmenysuli_2021) {
            if(kapRegistration.elmenysuli_2021_status_id == REGISTRATION_PENDING_ID) {
                elmenysuli2021Accepted = <span className="label warning">2021-Élménysuli pályázat még nincs elfogadva</span>
            } else if(kapRegistration.elmenysuli_2021_status_id == REGISTRATION_ACCEPTED_ID) {
                elmenysuli2021Accepted = <span className="label success">✔ 2021-Élménysuli pályázat elfogadva</span>
            } else if(kapRegistration.elmenysuli_2021_status_id == REGISTRATION_REJECTED_ID) {
                elmenysuli2021Accepted = <span className="label alert">❌ 2021-Élménysuli pályázat elutasítva</span>
            } else if(kapRegistration.elmenysuli_2021_status_id == REGISTRATION_RETIFICATION_ID) {
                elmenysuli2021Accepted = <span className="label warning">❌ 2021-Hiánypótlás</span>
            }
        }
        
        let elmenysuli2022Accepted = <span className="label secondary">2022-Még nem pályázott élménysulira</span>;
        if (kapRegistration.elmenysuli_2022) {
            if(kapRegistration.elmenysuli_2022_status_id == REGISTRATION_PENDING_ID) {
                elmenysuli2022Accepted = <span className="label warning">2022-Élménysuli pályázat még nincs elfogadva</span>
            } else if(kapRegistration.elmenysuli_2022_status_id == REGISTRATION_ACCEPTED_ID) {
                elmenysuli2022Accepted = <span className="label success">✔ 2022-Élménysuli pályázat elfogadva</span>
            } else if(kapRegistration.elmenysuli_2022_status_id == REGISTRATION_REJECTED_ID) {
                elmenysuli2022Accepted = <span className="label alert">❌ 2022-Élménysuli pályázat elutasítva</span>
            } else if(kapRegistration.elmenysuli_2022_status_id == REGISTRATION_RETIFICATION_ID) {
                elmenysuli2022Accepted = <span className="label warning">❌ 2022-Hiánypótlás</span>
            }
        }
        
        let elmenysuli2023Accepted = <span className="label secondary">2023-Még nem pályázott élménysulira</span>;
        if (kapRegistration.elmenysuli_2023) {
            if(kapRegistration.elmenysuli_2023_status_id == REGISTRATION_PENDING_ID) {
                elmenysuli2023Accepted = <span className="label warning">2023-Élménysuli pályázat még nincs elfogadva</span>
            } else if(kapRegistration.elmenysuli_2023_status_id == REGISTRATION_ACCEPTED_ID) {
                elmenysuli2023Accepted = <span className="label success">✔ 2023-Élménysuli pályázat elfogadva</span>
            } else if(kapRegistration.elmenysuli_2023_status_id == REGISTRATION_REJECTED_ID) {
                elmenysuli2023Accepted = <span className="label alert">❌ 2023-Élménysuli pályázat elutasítva</span>
            } else if(kapRegistration.elmenysuli_2023_status_id == REGISTRATION_RETIFICATION_ID) {
                elmenysuli2023Accepted = <span className="label warning">❌ 2023-Hiánypótlás</span>
            }
        }
        
        let elmenysuli2024Accepted = <span className="label secondary">2024-Még nem pályázott élménysulira</span>;
        if (kapRegistration.elmenysuli_2024) {
            if(kapRegistration.elmenysuli_2024_status_id == REGISTRATION_PENDING_ID) {
                elmenysuli2024Accepted = <span className="label warning">2024-Élménysuli pályázat még nincs elfogadva</span>
            } else if(kapRegistration.elmenysuli_2024_status_id == REGISTRATION_ACCEPTED_ID) {
                elmenysuli2024Accepted = <span className="label success">✔ 2024-Élménysuli pályázat elfogadva</span>
            } else if(kapRegistration.elmenysuli_2024_status_id == REGISTRATION_REJECTED_ID) {
                elmenysuli2024Accepted = <span className="label alert">❌ 2024-Élménysuli pályázat elutasítva</span>
            } else if(kapRegistration.elmenysuli_2024_status_id == REGISTRATION_RETIFICATION_ID) {
                elmenysuli2024Accepted = <span className="label warning">❌ 2024-Hiánypótlás</span>
            }
        }
        
        let previousElmenysuliRegistrationCount = 0;
        if (kapRegistration.elmenysuli_status_id == REGISTRATION_ACCEPTED_ID) previousElmenysuliRegistrationCount++;
        if (kapRegistration.elmenysuli_2020_status_id == REGISTRATION_ACCEPTED_ID) previousElmenysuliRegistrationCount++;
        if (kapRegistration.elmenysuli_2021_status_id == REGISTRATION_ACCEPTED_ID) previousElmenysuliRegistrationCount++;
        if (kapRegistration.elmenysuli_2022_status_id == REGISTRATION_ACCEPTED_ID) previousElmenysuliRegistrationCount++;
        if (kapRegistration.elmenysuli_2023_status_id == REGISTRATION_ACCEPTED_ID) previousElmenysuliRegistrationCount++;
        
        
        return <div className="row" style={{marginTop: "1em"}}>
            <div className="column small-12">
                <img src="/img/kap/eke_logo.png"/>
                <img src="/img/kap/kap_logo.png" style={{marginLeft: "1em"}}/>
                
                <h3 style={{margin: "1em 0"}}>Részletek</h3>
            </div>

            <div className="column small-12">
                <KAPBaseDataShow registration={registration} />

                <div style={{pageBreakAfter: "always"}} />

                <h4>KAPOCS követelmények:</h4>
                
                <KAPRequirementsShow registration={registration} />

                {registrationAccepted}
                {
                    <>
                        <br/>
                        <br/>
                        <button className="button primary" onClick={this.onAcceptRegistration.bind(this)}>Elfogadás</button>
                        <br/>
                        <button className="button alert" onClick={this.onRejectRegistration.bind(this)}>Elutasítás</button>
                    </>
                }

                <hr/>

                
                {
                    elmenysuli
                    ?
                    <>
                        <h4>
                            Élménysuli 2019 követelmények:
                        </h4>
                        <KAPElmenysuliRequirementsShow elmenysuli={elmenysuli} />

                        {elmenysuliAccepted}

                        {
                            <>
                                <br/>
                                <br/>
                                <button className="button primary" onClick={this.onAcceptElmenysuli.bind(this)}>Elfogadás</button>
                                <br/>
                                <button className="button alert" onClick={this.onRejectElmenysuli.bind(this)}>Elutasítás</button>
                                <br/>
                                <button className="button alert" onClick={this.onRetificateElmenysuli.bind(this)}>Hiánypótlás szükséges</button>
                            </>
                        }
                    </>
                    :
                    null
                }

{
                    elmenysuli2020
                    ?
                    <>
                        <h4>
                            Élménysuli {kapRegistration.elmenysuli_status_id == REGISTRATION_ACCEPTED_ID ? " cím megújítása " : ""} pályázat 2020 követelmények:
                        </h4>
                        <KAPElmenysuli2020RequirementsShow elmenysuli={elmenysuli2020} />

                        {elmenysuli2020Accepted}

                        {
                            <>
                                <br/>
                                <br/>
                                <button className="button primary" onClick={this.onAcceptElmenysuli2020.bind(this)}>Elfogadás</button>
                                <br/>
                                <button className="button alert" onClick={this.onRejectElmenysuli2020.bind(this)}>Elutasítás</button>
                                <br/>
                                <button className="button alert" onClick={this.onRetificateElmenysuli2020.bind(this)}>Hiánypótlás szükséges</button>
                            </>
                        }
                    </>
                    :
                    null
                }

                {
                    elmenysuli2021
                    ?
                    <>
                        <KAPElmenysuli2021RequirementsShow elmenysuli={elmenysuli2021} />

                        {elmenysuli2021Accepted}

                        {
                            <>
                                <br/>
                                <br/>
                                <button className="button primary" onClick={this.onAcceptElmenysuli2021.bind(this)}>Elfogadás</button>
                                <br/>
                                <button className="button alert" onClick={this.onRejectElmenysuli2021.bind(this)}>Elutasítás</button>
                                <br/>
                                <button className="button alert" onClick={this.onRetificateElmenysuli2021.bind(this)}>Hiánypótlás szükséges</button>
                            </>
                        }
                    </>
                    :
                    null
                }

                {
                    elmenysuli2022
                    ?
                    <>
                        <KAPElmenysuli2022RequirementsShow elmenysuli={elmenysuli2022} />

                        {elmenysuli2022Accepted}

                        {
                            <>
                                <br/>
                                <br/>
                                <button className="button primary" onClick={this.onAcceptElmenysuli2022.bind(this)}>Elfogadás</button>
                                <br/>
                                <button className="button alert" onClick={this.onRejectElmenysuli2022.bind(this)}>Elutasítás</button>
                                <br/>
                                <button className="button alert" onClick={this.onRetificateElmenysuli2022.bind(this)}>Hiánypótlás szükséges</button>
                            </>
                        }
                    </>
                    :
                    null
                }

{
                    elmenysuli2023
                    ?
                    <>
                        <KAPElmenysuli2023RequirementsShow elmenysuli={elmenysuli2023} />

                        {elmenysuli2023Accepted}

                        {
                            <>
                                <br/>
                                <br/>
                                <button className="button primary" onClick={this.onAcceptElmenysuli2023.bind(this)}>Elfogadás</button>
                                <br/>
                                <button className="button alert" onClick={this.onRejectElmenysuli2023.bind(this)}>Elutasítás</button>
                                <br/>
                                <button className="button alert" onClick={this.onRetificateElmenysuli2023.bind(this)}>Hiánypótlás szükséges</button>
                            </>
                        }
                    </>
                    :
                    null
                }

                <h4>
                    {previousElmenysuliRegistrationCount === 0 && "Élménysuli pályázat 2024 követelmények:"}
                    {previousElmenysuliRegistrationCount === 1 && "Élménysuli cím megújítása pályázat 2024 követelmények:"}
                    {previousElmenysuliRegistrationCount === 2 && "Örökös Élménysuli pályázat 2024 követelmények:"}
                </h4>
                {
                    elmenysuli2024
                    ?
                    <>
                        <KAPElmenysuli2024RequirementsShow elmenysuli={elmenysuli2024} />

                        {elmenysuli2024Accepted}

                        {
                            <>
                                <br/>
                                <br/>
                                <button className="button primary" onClick={this.onAcceptElmenysuli2024.bind(this)}>Elfogadás</button>
                                <br/>
                                <button className="button alert" onClick={this.onRejectElmenysuli2024.bind(this)}>Elutasítás</button>
                                <br/>
                                <button className="button alert" onClick={this.onRetificateElmenysuli2024.bind(this)}>Hiánypótlás szükséges</button>
                            </>
                        }
                    </>
                    :
                    <div className="callout warning">
                        Még nem pályázott élménysulira
                    </div>
                }

                <br/>
                <br/>
                <hr/>
                <br/>
                <br/>

                <button className="button alert" onClick={this.onDelete.bind(this)}>
                    Iskola törlése
                </button>

                <br/>
                <br/>

            </div>

        </div>;
    }
    
}

import * as React from 'react';
import { getRegistrations, updateRegistration } from '../../KAPServer';
import { History } from 'history';
import { KAPBaseDataEdit, KAPRequirementsEdit, KAPBaseDataShow, KAPRequirementsShow, KAPElmenysuli2022NewRequirementsEdit, KAPElmenysuli2022OnceRenewRequirementsEdit, KAPElmenysuli2022TwiceRenewRequirementsEdit, KAPElmenysuli2022RequirementsShow } from './KAPComponents';
import { KapRegistration } from './KAPRegisterPage';
import { match } from 'react-router';
import { IKapRegistration, REGISTRATION_ACCEPTED_ID } from './KAPRegistrationListPage';
import { KapElmenysuli2022NewRegistration, KapElmenysuli2022OnceRenewRegistration, KapElmenysuli2022TwiceRenewRegistration } from './KAPElmenysuli2022RegisterPage';

type KAPEditPageProps = {
    match: match<{id: string}>;
    history: History;
}

type KAPEditPageState = {
    row?: IKapRegistration;
    registration?: KapRegistration;
    elmenysuliNewRegistration?: KapElmenysuli2022NewRegistration;
    elmenysuliOnceRenewRegistration?: KapElmenysuli2022OnceRenewRegistration;
    elmenysuliTwiceRenewRegistration?: KapElmenysuli2022TwiceRenewRegistration;
    errors: string[];
}

export class KAPEditPage extends React.Component<KAPEditPageProps, KAPEditPageState> {

    constructor(props: any) {
        super(props);

        this.state = {
            errors: []
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    async reloadAsync() {
        const registrations = await getRegistrations();
        if (registrations) {
            const r = registrations.find(r => "" + r.id == this.props.match.params.id);
            
            if (r) {

                let previousElmenysuliRegistrationCount = 0;
                if (r.elmenysuli_status_id == REGISTRATION_ACCEPTED_ID) previousElmenysuliRegistrationCount++;
                if (r.elmenysuli_2020_status_id == REGISTRATION_ACCEPTED_ID) previousElmenysuliRegistrationCount++;
                if (r.elmenysuli_2021_status_id == REGISTRATION_ACCEPTED_ID) previousElmenysuliRegistrationCount++;
                
                this.setState({
                    row: r,
                    registration: JSON.parse(r.registration),
                    elmenysuliNewRegistration: (previousElmenysuliRegistrationCount === 0 && r.elmenysuli_2022) ? JSON.parse(r.elmenysuli_2022) : undefined,
                    elmenysuliOnceRenewRegistration: (previousElmenysuliRegistrationCount === 1 && r.elmenysuli_2022) ? JSON.parse(r.elmenysuli_2022) : undefined,
                    elmenysuliTwiceRenewRegistration: (previousElmenysuliRegistrationCount >= 2 && r.elmenysuli_2022) ? JSON.parse(r.elmenysuli_2022) : undefined
                });
            }
        }
    }

    private onChange(key: keyof(KapRegistration), e: React.ChangeEvent<HTMLInputElement>) {
        if (!this.state.registration) return;

        this.state.registration[key] = e.target.value;

        this.setState({
            registration: this.state.registration
        });
    }

    private onCheck(key: keyof(KapRegistration), e: React.ChangeEvent<HTMLInputElement>) {
        if (!this.state.registration) return;

        this.state.registration[key] = e.target.checked;

        this.setState({
            registration: this.state.registration
        });
    }

    private onCheckElmenysuli2022New(key: keyof(KapElmenysuli2022NewRegistration), e: React.ChangeEvent<HTMLInputElement>) {
        if (!this.state.elmenysuliNewRegistration) return;

        this.state.elmenysuliNewRegistration[key] = e.target.checked;

        this.setState({
            elmenysuliNewRegistration: this.state.elmenysuliNewRegistration
        });
    }

    private onCheckElmenysuli2022OnceRenew(key: keyof(KapElmenysuli2022OnceRenewRegistration), e: React.ChangeEvent<HTMLInputElement>) {
        if (!this.state.elmenysuliOnceRenewRegistration) return;

        this.state.elmenysuliOnceRenewRegistration[key] = e.target.checked;

        this.setState({
            elmenysuliOnceRenewRegistration: this.state.elmenysuliOnceRenewRegistration
        });
    }
    
    private onCheckElmenysuli2022TwiceRenew(key: keyof(KapElmenysuli2022TwiceRenewRegistration), e: React.ChangeEvent<HTMLInputElement>) {
        if (!this.state.elmenysuliTwiceRenewRegistration) return;

        this.state.elmenysuliTwiceRenewRegistration[key] = e.target.checked;

        this.setState({
            elmenysuliTwiceRenewRegistration: this.state.elmenysuliTwiceRenewRegistration
        });
    }
    
    
    private async onSave() {
        if (!this.state.registration) return;

        const errors: string[] = [];

        if (errors.length > 0) {
            this.setState({errors});
            return;
        }

        try {
           const response = await updateRegistration(
               this.props.match.params.id, 
               this.state.registration, 
               null, 
               null,
               (this.state.elmenysuliNewRegistration || this.state.elmenysuliOnceRenewRegistration || this.state.elmenysuliTwiceRenewRegistration)
               ?
               Object.assign({}, this.state.elmenysuliNewRegistration, this.state.elmenysuliOnceRenewRegistration, this.state.elmenysuliTwiceRenewRegistration)
               :
               null
            );

            if (!response.success) {
                alert(response.error || "Szerver oldali hiba történt");
                return;
            }
            
            alert("Mentés sikeres!");

        } catch(e) {
            alert(e);
        }

    }

    render() {
        if (!this.state.registration || !this.state.row) {
            return null;
        }

        let previousElmenysuliRegistrationCount = 0;
        if (this.state.row.elmenysuli_status_id == REGISTRATION_ACCEPTED_ID) previousElmenysuliRegistrationCount++;
        if (this.state.row.elmenysuli_2020_status_id == REGISTRATION_ACCEPTED_ID) previousElmenysuliRegistrationCount++;
        if (this.state.row.elmenysuli_2021_status_id == REGISTRATION_ACCEPTED_ID) previousElmenysuliRegistrationCount++;
        
        return <div className="row kap-registration-form" style={{marginTop: "1em"}}>
            <div className="column small-12">
                <img src="/img/kap/eke_logo.png"/>
                <img src="/img/kap/kap_logo.png" style={{marginLeft: "1em"}}/>
                
                <h3 style={{margin: "1em 0"}}>
                    Módosítás
                </h3>

                {
                    this.props.match.path.includes("modify_base")
                    ?
                    <>
                        <KAPBaseDataEdit showPassword={false} registration={this.state.registration} onChange={this.onChange.bind(this)} />
                        <button className="button primary" onClick={this.onSave.bind(this)}>
                            Adatok mentése
                        </button>
                    </>
                    :
                    <KAPBaseDataShow registration={this.state.registration} />
                }
            
                <h4>
                    KAPOCS regisztrációs feltételek:
                </h4>
 
                {
                    this.props.match.path.includes("modify_kapocs")
                    ?
                    <>
                        <KAPRequirementsEdit registration={this.state.registration} onChange={this.onCheck.bind(this)} />
                        <button className="button primary" onClick={this.onSave.bind(this)}>
                            Adatok mentése
                        </button>
                    </>
                    :
                    <KAPRequirementsShow registration={this.state.registration} />
                }
                
                {
                    this.state.row && this.state.row.elmenysuli_2022 &&
                    <>
                        <h4>
                            Élménysuli 2022 pályázat feltételek:
                        </h4>
        
                        {
                            this.props.match.path.includes("modify_elmenysuli")
                            ?
                            <>
                                {
                                    previousElmenysuliRegistrationCount === 0 &&
                                    <KAPElmenysuli2022NewRequirementsEdit elmenysuliRegistration={this.state.elmenysuliNewRegistration!} onChange={this.onCheckElmenysuli2022New.bind(this)} />
                                }
                                {
                                    previousElmenysuliRegistrationCount === 1 &&
                                    <KAPElmenysuli2022OnceRenewRequirementsEdit elmenysuliRegistration={this.state.elmenysuliOnceRenewRegistration!} onChange={this.onCheckElmenysuli2022OnceRenew.bind(this)} />
                                }
                                {
                                    previousElmenysuliRegistrationCount === 2 &&
                                    <KAPElmenysuli2022TwiceRenewRequirementsEdit elmenysuliRegistration={this.state.elmenysuliTwiceRenewRegistration!} onChange={this.onCheckElmenysuli2022TwiceRenew.bind(this)} />
                                }
                                
                                <button className="button primary" onClick={this.onSave.bind(this)}>
                                    Adatok mentése
                                </button>
                            </>
                            :
                            <KAPElmenysuli2022RequirementsShow elmenysuli={this.state.elmenysuliNewRegistration || this.state.elmenysuliOnceRenewRegistration || this.state.elmenysuliTwiceRenewRegistration} />
                        }

                    </>
                }
                
                {
                    this.state.errors.length > 0 &&
                    <div className="callout alert">
                        <h5></h5>
                        <ul>
                            {this.state.errors.map(e => <li>{e}</li>)}
                        </ul>
                    </div>
                }
                <br/>
            </div>
        </div>;
    }
    
}

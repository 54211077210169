import * as React from 'react';
import { getRegistrations } from '../../KAPServer';
import { Link } from 'react-router-dom';
import { me } from '../../framework/server/Auth';

export const REGISTRATION_PENDING_ID = 1;
export const REGISTRATION_ACCEPTED_ID = 2;
export const REGISTRATION_REJECTED_ID = 3;
export const REGISTRATION_RETIFICATION_ID = 4;

export interface IKapRegistration {
    id: number;
    registration: any;
    creation_time: string;
    registration_status_id: number,
    
    elmenysuli: any;
    elmenysuli_status_id: number,
    elmenysuli_no: number,
    elmenysuli_creation_time: string;
    
    elmenysuli_2020: any;
    elmenysuli_2020_status_id: number,
    elmenysuli_2020_no: number,
    elmenysuli_2020_creation_time: string;

    elmenysuli_2021: any;
    elmenysuli_2021_status_id: number,
    elmenysuli_2021_no: number,
    elmenysuli_2021_creation_time: string;

    elmenysuli_2022: any;
    elmenysuli_2022_status_id: number,
    elmenysuli_2022_no: number,
    elmenysuli_2022_creation_time: string;

    elmenysuli_2023: any;
    elmenysuli_2023_status_id: number,
    elmenysuli_2023_no: number,
    elmenysuli_2023_creation_time: string;

    elmenysuli_2024: any;
    elmenysuli_2024_status_id: number,
    elmenysuli_2024_no: number,
    elmenysuli_2024_creation_time: string;
}

type KAPRegistrationListPageState = {
    kapRegistrations: IKapRegistration[];
    onlyHasPending: boolean;
}

export class KAPRegistrationListPage extends React.Component<any, KAPRegistrationListPageState> {

    constructor(props: any) {
        super(props);

        this.state = {
            kapRegistrations: [],
            onlyHasPending: false
        }
    }

    async componentDidMount() {
        const registrations = await getRegistrations();
        if (registrations) {
            this.setState({
                kapRegistrations: registrations
            });
        }
        
    }

    render() {
        const onlyPendingCount = this.state.kapRegistrations.filter(r => {
            if (r.registration_status_id == REGISTRATION_PENDING_ID) return true;
            if (r.elmenysuli && r.elmenysuli_status_id == REGISTRATION_PENDING_ID) return true;
            if (r.elmenysuli_2020 && r.elmenysuli_2020_status_id == REGISTRATION_PENDING_ID) return true;
            if (r.elmenysuli_2021 && r.elmenysuli_2021_status_id == REGISTRATION_PENDING_ID) return true;
            if (r.elmenysuli_2022 && r.elmenysuli_2022_status_id == REGISTRATION_PENDING_ID) return true;
            if (r.elmenysuli_2023 && r.elmenysuli_2023_status_id == REGISTRATION_PENDING_ID) return true;
            if (r.elmenysuli_2024 && r.elmenysuli_2024_status_id == REGISTRATION_PENDING_ID) return true;
            
            return false;
        }).length;
        
        return <div className="row expanded">
            <div className="column small-12" style={{paddingTop: "2em"}}>
                <h4>Regisztrált feladatellátási helyek {this.state.kapRegistrations.length > 1 ? `(${this.state.kapRegistrations.length} db)`: null}:</h4>
                {
                    me && me.is_admin != 0 &&
                    <label>
                        <input type="checkbox" checked={this.state.onlyHasPending ? true : false} onChange={e => this.setState({onlyHasPending: e.target.checked})} /> Csak a <span className="label warning">Beküldve</span> státuszúakat mutassa ({onlyPendingCount} db)
                    </label>
                }
                <table>
                    <thead>
                        <tr>
                            <th>Adatok</th>

                            <th>Regisztráció adatai</th>

                            <th>Regisztrációkor megadott adatok</th>

                            {
                                me && me.is_admin != 0 &&
                                <>
                                <th>Élménysuli 2019</th>
                                <th>Élménysuli 2020</th>
                                <th>Élménysuli 2021</th>
                                <th>Élménysuli 2022</th>
                                <th>Élménysuli 2023</th>
                                </>
                            }
                            <th>Élménysuli 2024</th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.kapRegistrations.map(r => {
                                const registration = JSON.parse(r.registration);

                                let reg_date = r.creation_time;
                                if (reg_date) reg_date = reg_date.substring(0, 10);

                                let elmenysuli_creation_time = r.elmenysuli_creation_time;
                                if (elmenysuli_creation_time) elmenysuli_creation_time = elmenysuli_creation_time.substring(0, 10);

                                let elmenysuli_2020_creation_time = r.elmenysuli_2020_creation_time;
                                if (elmenysuli_2020_creation_time) elmenysuli_2020_creation_time = elmenysuli_2020_creation_time.substring(0, 10);

                                let elmenysuli_2021_creation_time = r.elmenysuli_2021_creation_time;
                                if (elmenysuli_2021_creation_time) elmenysuli_2021_creation_time = elmenysuli_2021_creation_time.substring(0, 10);

                                let elmenysuli_2022_creation_time = r.elmenysuli_2022_creation_time;
                                if (elmenysuli_2022_creation_time) elmenysuli_2022_creation_time = elmenysuli_2022_creation_time.substring(0, 10);

                                let elmenysuli_2023_creation_time = r.elmenysuli_2023_creation_time;
                                if (elmenysuli_2023_creation_time) elmenysuli_2023_creation_time = elmenysuli_2023_creation_time.substring(0, 10);

                                let elmenysuli_2024_creation_time = r.elmenysuli_2024_creation_time;
                                if (elmenysuli_2024_creation_time) elmenysuli_2024_creation_time = elmenysuli_2024_creation_time.substring(0, 10);


                                let registrationAccepted = <span className="label warning">Beküldve</span>;
                                if(r.registration_status_id == REGISTRATION_ACCEPTED_ID) {
                                    registrationAccepted = <span className="label success">✔ Elfogadva</span>
                                } else if(r.registration_status_id == REGISTRATION_REJECTED_ID) {
                                    registrationAccepted = <span className="label alert">❌ Elutasítva</span>
                                }

                                let elmenysuliAccepted = (me.is_admin == 1) ? <span className="label secondary">2019-Nem pályázott</span> : "";
                                if (r.elmenysuli) {
                                    if(r.elmenysuli_status_id == REGISTRATION_PENDING_ID) {
                                        elmenysuliAccepted = <span className="label warning">2019-Beküldve</span>
                                    } else if(r.elmenysuli_status_id == REGISTRATION_ACCEPTED_ID) {
                                        elmenysuliAccepted = <span className="label success">✔ 2019-Elfogadva</span>
                                    } else if(r.elmenysuli_status_id == REGISTRATION_REJECTED_ID) {
                                        elmenysuliAccepted = <span className="label alert">❌ 2019-Elutasítva</span>
                                    } else if(r.elmenysuli_status_id == REGISTRATION_RETIFICATION_ID) {
                                        elmenysuliAccepted = <span className="label warning">❌ 2019-Hiánypótlás</span>
                                    }
                                }

                                let elmenysuli2020Accepted = (me.is_admin == 1) ? <span className="label secondary">2020-Nem pályázott</span> : "";
                                if (r.elmenysuli_2020) {
                                    if(r.elmenysuli_2020_status_id == REGISTRATION_PENDING_ID) {
                                        elmenysuli2020Accepted = <span className="label warning">2020-Beküldve</span>
                                    } else if(r.elmenysuli_2020_status_id == REGISTRATION_ACCEPTED_ID) {
                                        elmenysuli2020Accepted = <span className="label success">✔ 2020-Elfogadva</span>
                                    } else if(r.elmenysuli_2020_status_id == REGISTRATION_REJECTED_ID) {
                                        elmenysuli2020Accepted = <span className="label alert">❌ 2020-Elutasítva</span>
                                    } else if(r.elmenysuli_2020_status_id == REGISTRATION_RETIFICATION_ID) {
                                        elmenysuli2020Accepted = <span className="label warning">❌ 2020-Hiánypótlás</span>
                                    }
                                }

                                let elmenysuli2021Accepted = (me.is_admin == 1) ? <span className="label secondary">2021-Nem pályázott</span> : "";
                                if (r.elmenysuli_2021) {
                                    if(r.elmenysuli_2021_status_id == REGISTRATION_PENDING_ID) {
                                        elmenysuli2021Accepted = <span className="label warning">2021-Beküldve</span>
                                    } else if(r.elmenysuli_2021_status_id == REGISTRATION_ACCEPTED_ID) {
                                        elmenysuli2021Accepted = <span className="label success">✔ 2021-Elfogadva</span>
                                    } else if(r.elmenysuli_2021_status_id == REGISTRATION_REJECTED_ID) {
                                        elmenysuli2021Accepted = <span className="label alert">❌ 2021-Elutasítva</span>
                                    } else if(r.elmenysuli_2021_status_id == REGISTRATION_RETIFICATION_ID) {
                                        elmenysuli2021Accepted = <span className="label warning">❌ 2021-Hiánypótlás</span>
                                    }
                                }

                                let elmenysuli2022Accepted = (me.is_admin == 1) ? <span className="label secondary">2022-Nem pályázott</span> : "";
                                if (r.elmenysuli_2022) {
                                    if(r.elmenysuli_2022_status_id == REGISTRATION_PENDING_ID) {
                                        elmenysuli2022Accepted = <span className="label warning">2022-Beküldve</span>
                                    } else if(r.elmenysuli_2022_status_id == REGISTRATION_ACCEPTED_ID) {
                                        elmenysuli2022Accepted = <span className="label success">✔ 2022-Elfogadva</span>
                                    } else if(r.elmenysuli_2022_status_id == REGISTRATION_REJECTED_ID) {
                                        elmenysuli2022Accepted = <span className="label alert">❌ 2022-Elutasítva</span>
                                    } else if(r.elmenysuli_2022_status_id == REGISTRATION_RETIFICATION_ID) {
                                        elmenysuli2022Accepted = <span className="label warning">❌ 2022-Hiánypótlás</span>
                                    }
                                }

                                let elmenysuli2023Accepted = (me.is_admin == 1) ? <span className="label secondary">2023-Nem pályázott</span> : "";
                                if (r.elmenysuli_2023) {
                                    if(r.elmenysuli_2023_status_id == REGISTRATION_PENDING_ID) {
                                        elmenysuli2023Accepted = <span className="label warning">2023-Beküldve</span>
                                    } else if(r.elmenysuli_2023_status_id == REGISTRATION_ACCEPTED_ID) {
                                        elmenysuli2023Accepted = <span className="label success">✔ 2023-Elfogadva</span>
                                    } else if(r.elmenysuli_2023_status_id == REGISTRATION_REJECTED_ID) {
                                        elmenysuli2023Accepted = <span className="label alert">❌ 2023-Elutasítva</span>
                                    } else if(r.elmenysuli_2023_status_id == REGISTRATION_RETIFICATION_ID) {
                                        elmenysuli2023Accepted = <span className="label warning">❌ 2023-Hiánypótlás</span>
                                    }
                                }

                                let elmenysuli2024Accepted = (me.is_admin == 1) ? <span className="label secondary">2024-Nem pályázott</span> : "";
                                if (r.elmenysuli_2024) {
                                    if(r.elmenysuli_2024_status_id == REGISTRATION_PENDING_ID) {
                                        elmenysuli2024Accepted = <span className="label warning">2024-Beküldve</span>
                                    } else if(r.elmenysuli_2024_status_id == REGISTRATION_ACCEPTED_ID) {
                                        elmenysuli2024Accepted = <span className="label success">✔ 2024-Elfogadva</span>
                                    } else if(r.elmenysuli_2024_status_id == REGISTRATION_REJECTED_ID) {
                                        elmenysuli2024Accepted = <span className="label alert">❌ 2024-Elutasítva</span>
                                    } else if(r.elmenysuli_2024_status_id == REGISTRATION_RETIFICATION_ID) {
                                        elmenysuli2024Accepted = <span className="label warning">❌ 2024-Hiánypótlás</span>
                                    }
                                }

                                if (this.state.onlyHasPending) {
                                    let hasPending = false;
                                    if (r.registration_status_id == REGISTRATION_PENDING_ID) hasPending = true;
                                    if (r.elmenysuli && r.elmenysuli_status_id == REGISTRATION_PENDING_ID) hasPending = true;
                                    if (r.elmenysuli_2020 && r.elmenysuli_2020_status_id == REGISTRATION_PENDING_ID) hasPending = true;
                                    if (r.elmenysuli_2021 && r.elmenysuli_2021_status_id == REGISTRATION_PENDING_ID) hasPending = true;
                                    if (r.elmenysuli_2022 && r.elmenysuli_2022_status_id == REGISTRATION_PENDING_ID) hasPending = true;
                                    if (r.elmenysuli_2023 && r.elmenysuli_2023_status_id == REGISTRATION_PENDING_ID) hasPending = true;
                                    if (r.elmenysuli_2024 && r.elmenysuli_2024_status_id == REGISTRATION_PENDING_ID) hasPending = true;

                                    if (!hasPending) return null;
                                }

                                return <React.Fragment key={r.id}>
                                <tr>
                                    <td>
                                        <strong>{registration.institute_site_name}</strong> <br/>
                                        {registration.institute_site_county}, {registration.institute_site_zip}, {registration.institute_site_city}, {registration.institute_site_address} <br />
                                        OM: {registration.institute_om_identifier} {registration.institute_site_identifier}, Tel: {registration.institute_site_phone}, Email: {(r as any)["email"]}
                                    </td>

                                    <td>
                                        {reg_date} <br/>
                                        {r.id && "KAPOCS-" + pad3Zeros(r.id)} <br/>
                                        {registrationAccepted}
                                    </td>

                                    <td>
                                        {
                                            me && me.is_admin != 0
                                            ?
                                            <Link to={"/kap/details/" + r.id} className="button small primary" style={{margin: 0}}>
                                                Megtekintés
                                            </Link>
                                            :
                                            <>
                                                <Link to={"/kap/print/" + r.id} className="button small primary" style={{marginBottom: 0, marginRight: "1em"}}>
                                                    Megtekintés / nyomtatás
                                                </Link>

                                                <Link to={"/kap/modify_base/" + r.id} className="button small primary" style={{marginBottom: 0, marginRight: "1em"}}>
                                                    Módosítás
                                                </Link>
                                                {
                                                    r.registration_status_id == REGISTRATION_REJECTED_ID &&
                                                    <Link to={"/kap/modify_kapocs/" + r.id} className="button small primary" style={{marginBottom: 0}}>
                                                        Feltételek módosítása
                                                    </Link>
                                                }
                                                
                                            </>
                                        }
                                    </td>

                                    {
                                        me && me.is_admin != 0 &&
                                        <td>
                                            {elmenysuli_creation_time ? <>{elmenysuli_creation_time}<br/></> : null}
                                            {r.elmenysuli_no ? <>{"ÉLMÉNYSULI-" + pad3Zeros(r.elmenysuli_no)}<br/></> : null}
                                            {elmenysuliAccepted ? <>{elmenysuliAccepted}<br/></> : null}

                                            {
                                                me && me.is_admin != 0
                                                ?
                                                    r.elmenysuli && <Link to={"/kap/details/" + r.id} className="button small primary" style={{margin: "0.5em 0"}}>
                                                        Megtekintés
                                                    </Link>
                                                :
                                                    r.elmenysuli &&
                                                        <Link to={"/kap/elmenysuli_print/" + r.id}className="button small primary" style={{margin: "0.5em 0"}}>
                                                            Megtekintés
                                                        </Link>
                                            }
                                        </td>
                                    }
                                    
                                    {
                                        me && me.is_admin != 0 &&
                                        <td>
                                            {elmenysuli_2020_creation_time ? <>{elmenysuli_2020_creation_time}<br/></> : null}
                                            {r.elmenysuli_2020_no ? <>{"ÉLMÉNYSULI2020-" + pad3Zeros(r.elmenysuli_2020_no)}<br/></> : null}
                                            {elmenysuli2020Accepted ? <>{elmenysuli2020Accepted}<br/></> : null}
                                            {
                                                me && me.is_admin != 0
                                                ?
                                                    r.elmenysuli_2020 && <Link to={"/kap/details/" + r.id} className="button small primary" style={{margin: "0.5em 0"}}>
                                                        Megtekintés
                                                    </Link>
                                                :
                                                    r.elmenysuli_2020 &&
                                                        <Link to={"/kap/elmenysuli_2020_print/" + r.id}className="button small primary" style={{margin: "0.5em 0"}}>
                                                            Megtekintés
                                                        </Link>
                                            }
                                        </td>
                                    }

                                    {
                                        me && me.is_admin != 0 &&
                                        <td>
                                            {elmenysuli_2021_creation_time ? <>{elmenysuli_2021_creation_time}<br/></> : null}
                                            {r.elmenysuli_2021_no ? <>{"ÉLMÉNYSULI2021-" + pad3Zeros(r.elmenysuli_2021_no)}<br/></> : null}
                                            {elmenysuli2021Accepted ? <>{elmenysuli2021Accepted}<br/></> : null}
                                            {
                                                me && me.is_admin != 0
                                                ?
                                                    r.elmenysuli_2021 && <Link to={"/kap/details/" + r.id} className="button small primary" style={{margin: "0.5em 0"}}>
                                                        Megtekintés
                                                    </Link>
                                                :
                                                    r.elmenysuli_2021 &&
                                                        <Link to={"/kap/elmenysuli_2021_print/" + r.id}className="button small primary" style={{margin: "0.5em 0"}}>
                                                            Megtekintés
                                                        </Link>
                                            }
                                        </td>
                                    }

                                    {
                                        me && me.is_admin != 0 &&
                                        <td>
                                            {elmenysuli_2022_creation_time ? <>{elmenysuli_2022_creation_time}<br/></> : null}
                                            {r.elmenysuli_2022_no ? <>{"ÉLMÉNYSULI2022-" + pad3Zeros(r.elmenysuli_2022_no)}<br/></> : null}
                                            {elmenysuli2022Accepted ? <>{elmenysuli2022Accepted}<br/></> : null}
                                            {
                                                me && me.is_admin != 0
                                                ?
                                                    r.elmenysuli_2022 && <Link to={"/kap/details/" + r.id} className="button small primary" style={{margin: "0.5em 0"}}>
                                                        Megtekintés
                                                    </Link>
                                                :
                                                    r.elmenysuli_2022 &&
                                                        <Link to={"/kap/elmenysuli_2022_print/" + r.id}className="button small primary" style={{margin: "0.5em 0"}}>
                                                            Megtekintés
                                                        </Link>
                                            }
                                        </td>
                                    }

{
                                        me && me.is_admin != 0 &&
                                        <td>
                                            {elmenysuli_2023_creation_time ? <>{elmenysuli_2023_creation_time}<br/></> : null}
                                            {r.elmenysuli_2023_no ? <>{"ÉLMÉNYSULI2023-" + pad3Zeros(r.elmenysuli_2023_no)}<br/></> : null}
                                            {elmenysuli2023Accepted ? <>{elmenysuli2023Accepted}<br/></> : null}
                                            {
                                                me && me.is_admin != 0
                                                ?
                                                    r.elmenysuli_2023 && <Link to={"/kap/details/" + r.id} className="button small primary" style={{margin: "0.5em 0"}}>
                                                        Megtekintés
                                                    </Link>
                                                :
                                                    r.elmenysuli_2023 &&
                                                        <Link to={"/kap/elmenysuli_2023_print/" + r.id}className="button small primary" style={{margin: "0.5em 0"}}>
                                                            Megtekintés
                                                        </Link>
                                            }
                                        </td>
                                    }

                                    <td>
                                        
                                        {elmenysuli_2024_creation_time ? <>{elmenysuli_2024_creation_time}<br/></> : null}
                                        {r.elmenysuli_2024_no ? <>{"ÉLMÉNYSULI2024-" + pad3Zeros(r.elmenysuli_2024_no)}<br/></> : null}
                                        {elmenysuli2024Accepted ? <>{elmenysuli2024Accepted}<br/></> : null}
                                        {
                                            me && me.is_admin != 0
                                            ?
                                                r.elmenysuli_2024 && <Link to={"/kap/details/" + r.id} className="button small primary" style={{margin: "0.5em 0"}}>
                                                    Megtekintés
                                                </Link>
                                            :
                                                r.registration_status_id == 2 && !r.elmenysuli_2024
                                                ?
                                                <Link to={"/kap/elmenysuli_2024/" + r.id}className="button small primary" style={{margin: "0.5em 0"}}>
                                                    Pályázat benyújtása
                                                </Link>
                                                :
                                                r.elmenysuli_2024
                                                    ?
                                                    <>
                                                    <Link to={"/kap/elmenysuli_2024_print/" + r.id}className="button small primary" style={{margin: "0.5em 0"}}>
                                                        Megtekintés / nyomtatás
                                                    </Link>
                                                    {
                                                        (r.elmenysuli_2024_status_id == REGISTRATION_RETIFICATION_ID || r.elmenysuli_2023_status_id == REGISTRATION_REJECTED_ID) &&
                                                        <Link to={"/kap/modify_elmenysuli_2024/" + r.id}className="button small primary" style={{margin: "0.5em 0"}}>
                                                            Módosítás
                                                        </Link>
                                                    }
                                                    </>
                                                    :
                                                    null
                                        }
                                    </td>

                                </tr>
                                </React.Fragment>
                                ;
                            })
                        }
                    </tbody>
                </table>

                <Link to="/kap/register" className="button primary">
                    Új KAPOCS regisztráció
                </Link>

            </div>
        </div>;
    }

}

function pad3Zeros(n: number) {
    let ret = "" + n;
    while(ret.length < 3) ret = '0' + ret; 
    return ret;
}

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { loadMe } from './framework/server/Auth';

async function init() {
    await loadMe();
    ReactDOM.render(<App />, document.getElementById('root'));
}

init();

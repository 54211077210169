import * as React from 'react';
import { me, loadMe } from '../../framework/server/Auth';
import { logout } from '../../KAPServer';
import { app } from '../../App';
import { Link } from 'react-router-dom';

declare var $: any;

export class KAPHeader extends React.Component {

    componentDidMount() {
        $(document).foundation();
    }

    private async onLogout() {
        await logout();
        location.replace("/");
    }
    
    render() {
        return <div className="top-bar hide-for-print">
            <div className="top-bar-left">
                <h5>Komplex Alapprogram</h5>
            </div>
            {
                me ?
                <>
                    <div className="top-bar-right">
                        <ul className="menu">
                            <li>
                                <Link to="/kap/list" style={{marginRight: "1em"}}>
                                    Regisztrált feladatellátási helyek
                                </Link>
                            </li>
                            <li>
                                <a href="#" onClick={this.onLogout.bind(this)}>
                                    Kijelentkezés
                                </a>
                            </li>
                            <li className="menu-text">
                                {me.email}
                            </li>
                        </ul>
                    </div>
                </>
                :
                <>
                    <div className="top-bar-right">
                        <ul className="menu">
                            <li>
                                <Link to="/login">
                                    Bejelentkezés
                                </Link>
                            </li>
                        </ul>
                    </div>
                </>
            }
        </div>;
    }

}
